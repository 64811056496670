import React, { useState, useEffect } from 'react';
import { Col, Image, Row } from "react-bootstrap";
import CircularProgress from "../../components/common/CircularProgress";
import { Link } from 'react-router-dom';
import axios from 'axios';

/**
 * ProjectNewsComponent - Renders the list of project partners.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.news - The list of news
 *
 * @returns {JSX.Element} The rendered component.
 */
const ProjectNewsComponent = ({ projectId }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);


    useEffect(() => {

        const fetchItemsData = async () => {
            setLoading(true);
            setError(null);

            const params = { projectId: projectId };

            try {
                const response = await axios.get('https://us-central1-bycbase-c9b76.cloudfunctions.net/fetchNewsByProjectId', { params });
                const fetchedItem = response.data;

                setItems(fetchedItem.news);

            } catch (error) {
                console.error('Error fetching projects:', error);
                this.setState({ error: error.message, loading: false });
            } finally {
                setLoading(false);
            }
        }

        fetchItemsData();
    }, [projectId]);


    const itemsPerPage = 3;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(items) ? items.slice(indexOfFirstItem, indexOfLastItem) : [];

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    if (loading) return <CircularProgress />;
    if (error) return <div>Error: {error}</div>;

    if (items.length === 0)
        return "";
    else

        return (
            <>
                <Row>
                    <h4>Related News ({items.length} items):</h4>
                    {currentItems.map((newsItem, index) => (
                        <Col key={index} xs={12} sm={6} md={6} lg={4} >
                            <ProjectNewsItem newsItem={newsItem} />
                        </Col>
                    ))}
                </Row>
                <Row className='mb-5'>
                    <Col>
                        <div className="pagination-controls">
                            <button onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
                            <span>{" " + currentPage} / {totalPages + " "}</span>
                            <button onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </Col>
                </Row>
            </>
        )
}

export default ProjectNewsComponent;


const ProjectNewsItem = ({ newsItem }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div>
            <div style={{ position: 'relative', width: '192px', height: '108px' }}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    {isLoading && (<CircularProgress />)}
                </div>
                <Image
                    src={newsItem.featuredImageUrl}
                    width={192}
                    height={108}
                    thumbnail
                    onLoad={handleImageLoad}
                />
            </div>
            <small className="text-muted" style={{ textAlign: 'right' }}>{new Date(newsItem.createdDate).toLocaleDateString()}</small>
            <p>
                <Link to={`/news/${newsItem.slug}`}>
                    {newsItem.title}</Link>
            </p>

        </div>

    );
}