import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css';
import NavbarComponent from './pages/components/NavbarComponent'
import MainPage from './pages/Main/MainPage';
import ProjectDetails from './pages/ProjectDetail/ProjectDetailsPage'
import Projects from './pages/Projects/ProjectsPage'
import News from './pages/News/NewsPage'
import NewsItemDetails from './pages/NewsDetail/NewsItemDetailPage';
import ContactPage from './pages/Contact/ContactPage';
import AboutPage from './pages/About/AboutPage';
import LoginPage from './pages/Login/LoginPage';
import AdminPage from './pages/Admin/AdminPage';
import AddNewsPage from './pages/NewsManagement/AddNews/AddNewsPage';
import UpdateNewsPage from './pages/NewsManagement/UpdateNews/UpdateNewsPage';
import NewsManagementPage from './pages/NewsManagement/NewsManagementPage';
import ProjectsManagementPage from './pages/ProjectManagement/ProjectsManagementPage';
import AddProjectPage from './pages/ProjectManagement/AddProject/AddProjectPage';
import UpdateProjectPage from './pages/ProjectManagement/UpdateProject/UpdateProjectPage';
import NotFoundPage from './pages/components/NotFoundPage';


function App() {


  return (
    <BrowserRouter>
      <div className="App">
        <NavbarComponent />

        <Switch>
          <Route exact path='/' component={MainPage} />
          <Route path='/projects' component={Projects} />
          <Route path='/project/:projectId' component={ProjectDetails} />

          <Route path='/news/page/:pageNumber' component={News} />
          <Route path='/news/:slug' component={NewsItemDetails} />
          <Route path='/news' component={News} />

          <Route path='/about-us' component={AboutPage} />
          <Route path='/contact' component={ContactPage} />

          <Route path='/login' component={LoginPage} />
          <Route path='/admin' component={AdminPage} />
          
          <Route path='/manage_news/' component={NewsManagementPage} />
          <Route path='/update_news/:newsId' component={UpdateNewsPage} />
          <Route path='/add_news' component={AddNewsPage} />
          
          <Route path='/manage_projects/' component={ProjectsManagementPage} />
          <Route path='/update_project/:projectId' component={UpdateProjectPage} />
          <Route path='/add_project' component={AddProjectPage} />

          <Route component={NotFoundPage} />


        </Switch>


      </div>
    </BrowserRouter>
  );
}

export default App;
