import { Badge, Row, Col } from 'react-bootstrap';
import { BsBell, BsCalendar2, BsFolder, BsLink45Deg } from "react-icons/bs";
import DOMPurify from 'dompurify';
import { projectStatuses } from '../../Consts';

/**
 * ProjectPartnerListComponent - Renders the list of project partners.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.project - Project elemenmt
 *
 * @returns {JSX.Element} The rendered component.
 */

const ProjectInfoComponent = ({ project }) => {
    const topicsArray = project?.topics?.split(',');
    const cleanHTML = DOMPurify.sanitize(project.description);

    return (
        <>
            <Row className=''>
                <Col md={6} className='mb-4'>
                    <div className="d-flex align-items-center">
                        <BsBell size={48} className="me-2" color='blue' />
                        <div>
                            <span className="text-primary">Project Status:</span>
                            <br />
                            <span><b>{projectStatuses[project?.projectStatus]}</b></span>
                        </div>

                    </div>
                </Col>

                <Col md={6} className='mb-4'>
                    <div className="d-flex align-items-center">
                        <BsLink45Deg size={48} className="me-2" color='blue' />

                        <div>
                            <span className="text-primary">Referance: </span>
                            <br />
                            <span><b>{project.referanceNumber}</b>  </span>
                        </div>

                    </div>
                </Col>
            </Row>

            <Row className=''>


                <Col md={6} className='mb-4'>
                    <div className="d-flex align-items-center">
                        <BsCalendar2 size={48} className="me-2" color='blue' />

                        <div>
                            <span><span className="text-primary">Start Date: </span><b>{project.startDate}</b>  </span>
                            <br />
                            <span><span className="text-primary">End Date: </span><b>{project.endDate}</b>  </span>
                        </div>

                    </div>
                </Col>

                <Col md={6} className='mb-4'>
                    <div className="d-flex align-items-center">
                        <BsFolder size={48} className="me-2" color='blue' />
                        <div>
                            <span className="text-primary">Project Type:</span>
                            <br />
                            <span><b>{project.projectType} </b></span>
                        </div>

                    </div>
                </Col>


            </Row>

            <Row className='mb-4'>
                <Col>
                    <h4>Topics:</h4>
                    {
                        topicsArray?.map((tag, index) => (
                            <Badge key={index} className="me-2 mb-2">{tag.trim()}</Badge>
                        ))
                    }
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col>
                    <h4>Description:</h4>
                    <DescriptionComponent description={cleanHTML} />

                </Col>
            </Row>
        </>

    )
}

export default ProjectInfoComponent;


function DescriptionComponent({ description }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: description }} />
    );
}