import React from 'react';
import { Carousel, Image, Container, Breadcrumb, Row, Col, Card } from 'react-bootstrap';
import { BsGlobeEuropeAfrica, BsSun, BsFlag, BsEmojiSmile, BsPlug } from "react-icons/bs";
import { IoFootstepsOutline } from "react-icons/io5";
import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';

function AboutPage() {
    const currentUrl = window.location.href;

    return (
        <div className='mb-8'>

            <Helmet>
                <title>About Us - Bright Youth Community</title>
                <meta name="description" content="We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields." />
                <meta name="keywords" content="youth, community, environmental conservation, project, erasmus, digital skills, creative thinking, communication, problem-solving" />
                <link rel="canonical" href={currentUrl} />

                <meta property="og:title" content="About Us - Bright Youth Community" />
                <meta property="og:description" content="We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us - Bright Youth Community" />
                <meta name="twitter:description" content="We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields." />
                <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
            </Helmet>

            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src="imgs/bg-about.jpg"
                        alt="About Us"
                        fluid
                        style={{
                            height: '360px', // İstediğiniz yükseklik
                            objectFit: 'cover', // Resmi kırparak kapsayıcıya sığdırır
                            objectPosition: 'center', // Resmi ortalar
                            opacity: 0.7
                        }}
                    />
                    <Carousel.Caption>
                        <h1 style={{ color: 'white' }}>About Us</h1>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

            <Container className="mt-4">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <div className='bg-light'>
                <Container className='mb-4'>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Image
                                src='imgs/about-us-1-518x430.jpg'
                                alt="about-us-intro"
                                fluid
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className="d-flex align-items-center">
                                <blockquote className="blockquote">
                                    <p className="display-6 mt-2 mb-4"><b>About Bright Youth <br /> Community</b></p>
                                    <footer className="blockquote-footer"><b>We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields..</b></footer>
                                </blockquote>

                            </div>
                            <p>
                                Our youth group, "Wonders of Milas," has been active since 2020. In order to enhance and expand the group's activities and initiatives, we established our association as "Bright Youth Community" in the year 2023.
                            </p>
                            <p>
                                We are here to protect environment, improve our young community’s well being and develop their skills and knowledge required in the 21st century! Our association develops projects that encompass digital skills, creative thinking, communication, problem-solving, and much more. Together with you, we strive to become the empowered and conscious youth of the future.
                            </p><p>
                                By combining our imagination, passion, and energy, we organize activities that benefit our surroundings and society. Are you ready for a journey filled with international youth exchanges, training sessions, seminars, and voluntary events?
                            </p><p>
                                We will be delighted to have you among us. Let's build the future together!
                            </p>

                        </Col>
                    </Row>



                </Container>

            </div>
            <Container className='mb-4'>
                <Row className='mb-4 mt-4'>
                    <Col className="text-center mt-4 mb-4">
                        <b className="display-5">Our Mission</b>
                    </Col>

                </Row>
                <Row className='mb-4'>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={4} lg={4}>
                                <BsGlobeEuropeAfrica size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <div className="box-icon-caption">
                                    <h4>Environmental Guardianship</h4>
                                    <p>We dedicate ourselves to safeguarding the environment, focusing on projects that promote sustainability and ecological awareness.</p>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={3} lg={3}>
                                <BsPlug size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9}>
                                <div className="box-icon-caption">
                                    <h4>Youth Empowerment Through Innovation</h4>
                                    <p>Our association pioneers innovative projects, fostering digital skills, creative thinking, communication, and problem-solving among the youth.</p>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={3} lg={3}>
                                <IoFootstepsOutline size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9}>
                                <div className="box-icon-caption">
                                    <h4>Steady Steps Towards Conscious Youth</h4>
                                    <p>Through imagination, passion, and energy, we organize activities that benefit both our community and society.</p>
                                </div>
                            </Col>
                        </Row>


                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={3} lg={3}>
                                <BsEmojiSmile size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9}>
                                <div className="box-icon-caption">
                                    <h4>Well-being and Skill Development</h4>
                                    <p>Committed to the well-being of our young community, we focus on developing essential 21st-century skills and knowledge for their holistic growth.</p>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={3} lg={3}>
                                <BsFlag size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9}>
                                <div className="box-icon-caption">
                                    <h4>International Engagement and Skill Building</h4>
                                    <p>Embark on a journey filled with international youth exchanges, training sessions, seminars, and voluntary events designed to enhance skills and global perspectives.</p>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <Row>
                            <Col className='text-center' xs={12} sm={12} md={3} lg={3}>
                                <BsSun size={96} className="me-2" color='green' opacity={0.5} />
                            </Col>
                            <Col xs={12} sm={12} md={9} lg={9}>
                                <div className="box-icon-caption">
                                    <h4>Skyward Bound</h4>
                                    <p>With our gaze set on the sky, we aim for limitless horizons, symbolizing our commitment to constant growth, exploration, and making a positive impact on the world.</p>
                                </div>
                            </Col>
                        </Row>


                    </Col>
                </Row>

            </Container>

            <div className='bg-light'>

                <Container className='mb-4'>
                    <Row className='mb-4 mt-4'>
                        <Col className="text-center mt-4 mb-4">
                            <b className="display-5">Our Team</b>
                        </Col>

                    </Row>

                    <Container fluid>
                        <Row className="no-gutters">
                            <Col md={6} lg={4}>
                                <Card className="team-classic">
                                    <Card.Img variant="top" src="imgs/byc-team-dilek.jpg" alt="" width="390" height="252" />
                                    <div className='text-center mt-2'>
                                        <h5>Dilek KEÇELİOĞLU</h5>
                                        <p>Chair of the Board</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} lg={4}>
                                <Card className="team-classic">
                                    <Card.Img variant="top" src="imgs/byc-team-ilhan.jpg" alt="" width="390" height="252" />
                                    <div className='text-center mt-2'>
                                        <h5>İlhan ÖZDEMİR</h5>
                                        <p>Deputy Chair</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} lg={4}>
                                <Card className="team-classic">
                                    <Card.Img variant="top" src="imgs/byc-team-hatice.jpg" alt="" width="390" height="252" />
                                    <div className='text-center mt-2'>
                                        <h5>Hatice KOCALAR</h5>
                                        <p>Secretary</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} lg={4}>
                                <Card className="team-classic">
                                    <Card.Img variant="top" src="imgs/byc-team-oznur.jpg" alt="" width="390" height="252" />
                                    <div className='text-center mt-2'>
                                        <h5>Öznur ÖZDEMİR</h5>
                                        <p>Treasury</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} lg={4}>
                                <Card className="team-classic">
                                    <Card.Img variant="top" src="imgs/byc-team-miyase.jpg" alt="" width="390" height="252" />
                                    <div className='text-center mt-2'>
                                        <h5>Miyase YURDAER</h5>
                                        <p>Board Member</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </Container>


            </div>


            <div>

                <BottomSide />
            </div>

        </div>



    );
}

export default AboutPage;