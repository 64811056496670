import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, listAll } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const apiKey = process.env.REACT_APP_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app); // Initialize Firestore if needed

const getAllFilesInFolder = async folderPath => {
  const storageRef = ref(storage, folderPath);
  try {
    const result = await listAll(storageRef);
    const files = result.items.map(item => item.fullPath);
    return files; 
  } catch (error) {
    console.error("Error fetching files:", error);
    return [];
  }
}

export { firebaseConfig, auth, storage, getAllFilesInFolder, firestore }; 