import React, {  useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../config/fbConfig';
import { AiOutlineClose } from 'react-icons/ai';
import { Image } from 'react-bootstrap';

const StorageImageComponent = ({ imageName, path, handleDelete }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const pathAndFile = path + '/' + imageName;

    useEffect(() => {
        // Function to get download URL from Firebase Storage
        const getDownloadUrl = async () => {
            try {
                const imageRef = ref(storage, pathAndFile);
                const url = await getDownloadURL(imageRef);
                setImageUrl(url);
                setIsLoading(false);
            } catch (error) {
                console.error('Error getting download URL:', error);
                setIsLoading(false);
            }
        };

        // Call the function to get download URL when component mounts
        if (imageName) {
            getDownloadUrl();
        } else {
            setIsLoading(false);
        }

    }, [pathAndFile]); // Run this effect when logoPath changes

    return (

        <div className="ms-2 mb-2" style={{ position: 'relative', width: '160px', height: '90px' }}>
            {isLoading ? (
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <>
                    <Image
                        src={`${imageUrl}/160x90`}
                        alt=""
                        thumbnail
                        style={{ width: '100%', height: '100%' }}
                    />
                    <AiOutlineClose
                        size={24}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            cursor: 'pointer',
                            color: 'blue',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            padding: '5px'
                        }}
                        onClick={() => handleDelete(imageName)}
                    />
                </>

            )}
        </div>

    );
}

export default StorageImageComponent;