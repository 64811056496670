import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { BsLink45Deg, BsInstagram, BsFacebook } from "react-icons/bs";

const WebLinksComponent = ({ project }) => {
    return (
        <Row>
            <Col className="text-end">
                <div>
                    {project?.accWebSite &&
                        <a target='_blank' rel='noopener noreferrer' href={project.accWebSite}>
                            <BsLink45Deg size={16} className="me-1 ms-3" color='blue' />

                            <span className="text-primary">Website</span>

                        </a>
                    }

                    {project?.accInstagram &&
                        <a target='_blank' rel='noopener noreferrer' href={project.accInstagram}>
                            <BsInstagram size={16} className="me-1 ms-3" color='blue' />

                            <span className="text-primary">Instagram</span>

                        </a>
                    }

                    {project?.accFacebook &&

                        <a target='_blank' rel='noopener noreferrer' href={project.accFacebook}>
                            <BsFacebook size={16} className="me-1 ms-3" color='blue' />

                            <span className="text-primary">Facebook</span>

                        </a>

                    }
                </div>
            </Col>
        </Row>
    );
}

export default WebLinksComponent;