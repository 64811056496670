import React, { useState } from 'react';
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import { Navbar, Nav, Container, Image } from 'react-bootstrap';

const NavbarComponent = (props) => {
    const { auth } = props;
    const [open, setOpen] = useState(false);


    const links = auth.uid ? <SignedOutLinks /> : <SignedOutLinks />

    return (

        <Navbar bg="light" expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/">
                    <Image src="/imgs/byc-logo.png" alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen(!open)} />
                <Navbar.Collapse id="basic-navbar-nav" className={open ? 'show' : ''}>
                    <Nav className="ms-auto">
                        {links}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth

    }
}

export default connect(mapStateToProps)(NavbarComponent)