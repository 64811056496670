const initialState = {
  news: [],
  error: null,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CREATE_NEWS':
      return {
        ...state,
        news: [...state.news, action.newsItem], // Add new news item to the state
        error: null, // Clear any previous error
      };

    case 'UPDATE_NEWS':
      return {
        ...state,
        news: [...state.news, action.newsItem], // Add new news item to the state
        error: null, // Clear any previous error
      };

    case 'DELETE_NEWS':
      //console.log('AAA Deleted news with id:', action.newsItem.id);
      return {
        ...state,
        news: state.news.filter((item) => item.id !== action.newsItem.id), // Remove deleted news item
        error: null, // Clear any previous error
      };

    case 'CREATE_NEWS_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    case 'UPDATE_NEWS_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    case 'DELETE_NEWS_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    default:
      return state;
  }
};

export default newsReducer;