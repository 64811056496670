export const owners = [
    "Wonders of Milas", 
    "Bright Youth Community"
];

export const partnerTypes = [
    "Partner", 
    "Coordinator"
];

export const projectStatuses = [
    "Ongoing", 
    "Completed"
];

export const newsStatus = [
    "Active",
    "Draft"
];

export const serverURL = "https://us-central1-bycbase-c9b76.cloudfunctions.net";