import { Badge, Container, Row, Col, Card } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag";
import { partnerTypes } from '../../Consts'

/**
 * ProjectPartnerListComponent - Renders the list of project partners.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.partners - The list of project partners.
 *
 * @returns {JSX.Element} The rendered component.
 */

const ProjectPartnerListComponent = ({ partners }) => {
    return (
        <Card bg="light" text="dark" className="mb-2">
            <Card.Header><b>Project Partners ({partners?.length})</b></Card.Header>
            {partners?.map((partner, index) => (
                <Card text='dark' key={index}>
                    <Card.Body>
                        <Card.Text><b>{partner.organisation}</b></Card.Text>
                        <Card.Text className="d-flex align-items-center">
                            <span className="d-flex align-items-center">
                                <ReactCountryFlag
                                    countryCode={partner.countryCode}
                                    svg
                                    style={{
                                        fontSize: '2em',
                                        lineHeight: '2em',
                                    }}
                                />
                            </span>
                            <span className="ms-2">{partner?.venue}</span>
                            <span className="ms-auto">
                                <Badge>{partnerTypes[partner.partnerType]}</Badge>
                            </span>
                        </Card.Text>

                    </Card.Body>

                </Card>
            ))}
        </Card>
    )
}

export default ProjectPartnerListComponent;