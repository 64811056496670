import React from 'react';
import { Image } from 'react-bootstrap';
import { Slide } from 'react-awesome-reveal';
import useScroll from './UseScrool';
import './Banner.css'; // CSS dosyanızın import edildiğinden emin olun

const FlyingImages = () => {
    const scrollPosition = useScroll();
  
    const images = [
      'imgs/animate-img-1.jpg',
      'imgs/animate-img-2.jpg',
      'imgs/animate-img-3.jpg',
      'imgs/animate-img-4.jpg',
      'imgs/animate-img-5.jpg',
      'imgs/animate-img-6.jpg',
    ];
  
    return (
      <div className="flying-images-container" style={{ position: 'absolute' }}>
        {images.map((src, index) => (
          <Slide direction="up" triggerOnce key={index}>
            <Image
              src={src}
              alt={`Flying Image ${index + 1}`}
              className="flying-image"
              style={{
                transform: `translate(${Math.random() * 600}px, ${Math.random() * 600}px)`, // Resimlerin başlangıç pozisyonunu rastgele belirliyoruz (0 ile 600 arası yatayda, 0 ile 600 arası dikeyde)
                transition: `transform ${Math.max(scrollPosition / 100, 0.1)}s ease-out`, // Animasyon hızı scroll pozisyonuna göre ayarlanıyor, minimum 0.1 saniye olarak belirleniyor
                width: 'auto', // Adjust image size as desired
                height: 'auto', // Adjust image size as desired
                margin: '20px', // Add some margin between images to prevent overlapping
              }}
            />
          </Slide>
        ))}
      </div>
    );
  };
  
  export default FlyingImages;