import React, { useState } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NewsListComponent = ({ news }) => {

    return (
        <Container className='mt-4'>
            <Container fluid className="mt-4">
                <Row>
                    {news && news.map((newsItem, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} >
                            <NewsItemCard newsItem={newsItem} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default NewsListComponent;

const NewsItemCard = ({ newsItem }) => {

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div>
            <div style={{ position: 'relative', width: '256px', height: '144px' }}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    {isLoading && (
                        <div className="spinner-border text-primary" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                </div>
                <Image
                    src={newsItem.featuredImageUrl}
                    thumbnail
                    width={256}
                    height={144}
                    onLoad={handleImageLoad}
                />
            </div>
            <small className="text-muted" style={{ textAlign: 'right' }}>{new Date(newsItem.createdDate).toLocaleDateString()}</small>
            <p>
                <Link to={`/news/${newsItem.slug}`}>
                    {newsItem.title}</Link>
            </p>

        </div>

    );
}