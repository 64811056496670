import React, { useState } from 'react';
import { Card, Button, } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProjectList = ({ projects }) => {
  return (
    <div className="row">
      {projects && projects.map(project => (

        <div key={project.id} className="col-md-6 mb-4">
          <ProjectCard
            projectTitle={project.title}
            projectType={project.projectType}
            projectStartEndDate={`Start: ${project.startDate} / End: ${project.endDate}`}
            logoUrl={project.projectLogoUrl}
            projectId={project.id}
          />
        </div>
      ))}
    </div>
  )

}

const ProjectCard = ({ projectTitle, projectType, projectStartEndDate, logoUrl, projectId }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Card style={{ width: '100%', transition: 'transform 0.3s' }}>
      <Card.Body>
        <div className="d-flex">
          <div className="border rounded p-3" style={{ width: '120px', height: '120px', position: 'relative' }}>
            {isLoading && (

              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                {isLoading && (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            )}
            <Card.Img
              variant="top"
              src={logoUrl ? logoUrl : "/imgs/project-logo-placeholder.png"}
              alt={"logo " + projectTitle}
              style={{ width: 96, height: 96, display: isLoading ? 'none' : 'block' }}
              onLoad={handleImageLoad}
            />
          </div>
          <div className="ms-3">
            <Card.Title>{projectTitle}</Card.Title>
            <Card.Text>
              {projectType}<br />
              {projectStartEndDate}
            </Card.Text>
            <Link to={`/project/${projectId}`}>
              <Button variant="primary">View Details</Button>
            </Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};


export default ProjectList