import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import { Container, Table } from 'react-bootstrap';
import BottomSide from '../components/BottomSide';
import AdminPanelMenuBar from '../../pages/Admin/AdminPanelMenuBar';

class AdminPage extends Component {

    render() {
        const { auth, newsCount, newsUpdated, projectsCount, projectsUpdated } = this.props;

        if (!auth.uid)
            return <Redirect to="/login" replace />;

        const formattedNewsUpdated = newsUpdated ? new Date(newsUpdated.seconds * 1000).toLocaleString() : '';
        const formattedProjectsUpdated = projectsUpdated ? new Date(projectsUpdated.seconds * 1000).toLocaleString() : '';

        return (


            <div>
                <AdminPanelMenuBar />

                <Container className='mt-5 mb-5'>
                    <h2 className="mt-4 mb-4">Statistics</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Count</th>
                                <th>Latest Update</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr key="news">
                                    <td>News</td>
                                    <td>{newsCount}</td>
                                    <td>{formattedNewsUpdated}</td>
                                </tr>
                                <tr key="projects">
                                    <td>Projects</td>
                                    <td>{projectsCount}</td>
                                    <td>{formattedProjectsUpdated}</td>
                                </tr>
                        </tbody>
                    </Table>
                </Container>
                <BottomSide/>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        newsCount: state.firestore.data.metadata ? state.firestore.data.metadata.newsCount?.count : undefined,
        newsUpdated: state.firestore.data.metadata ? state.firestore.data.metadata.newsCount?.updated : undefined,
        projectsCount: state.firestore.data.metadata ? state.firestore.data.metadata.projectsCount?.count : undefined,
        projectsUpdated: state.firestore.data.metadata ? state.firestore.data.metadata.projectsCount?.updated : undefined,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'metadata', doc: 'newsCount' },
        { collection: 'metadata', doc: 'projectsCount' },

    ])
)(AdminPage);