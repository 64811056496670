// MyModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { storage } from '../../config/fbConfig';
import { ref, deleteObject } from 'firebase/storage';
import CircularProgress from '../../components/common/CircularProgress';
import { useDispatch } from 'react-redux';
import { deleteNewsItem } from '../../store/actions/newsActions';

const DeleteNewsModal = ({ show, handleClose, message, newsItem }) => {

    const [isProgressing, setIsProgressing] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = async (e) => {
        e.preventDefault();

        setIsProgressing(true); // Show progress indicator

        try {

            if (newsItem.featuredImage) {
                const imageRef = ref(storage, "news/" + newsItem.featuredImage);
                const imageThumbRef = ref(storage, "news/thumb_" + newsItem.featuredImage);

                try {
                    await deleteObject(imageRef);
                    console.log("News image deleted");
                } catch (error) {
                    console.log("Error deleting logo image:", error);
                }

                try {
                    await deleteObject(imageThumbRef)
                    console.log("News image thumbnail deleted");
                } catch (error) {
                    console.log("Error deleting logo image thumbnail:", error);
                }

            }

            if (newsItem.newsImages && newsItem.newsImages.length > 0) {
                // Promises'leri saklayacağımız bir dizi oluşturuyoruz
                const deletePromises = newsItem.newsImages.map(imageName => {
                    const imageRef = ref(storage, "news/" + imageName);
                    const imageThumbRef = ref(storage, "news/thumb_" + imageName);
            
                    // Hem ana resmi hem de thumbnail'ini silme işlemi için Promises oluşturuyoruz
                    return Promise.all([
                        deleteObject(imageRef)
                            .then(() => {
                                console.log(`${imageName} deleted`);
                            })
                            .catch((error) => {
                                console.log(`Error deleting ${imageName}:`, error);
                            }),
            
                        deleteObject(imageThumbRef)
                            .then(() => {
                                console.log(`Thumbnail for ${imageName} deleted`);
                            })
                            .catch((error) => {
                                console.log(`Error deleting thumbnail for ${imageName}:`, error);
                            })
                    ]);
                });
            
                // Tüm Promises'leri işleme alıyoruz
                Promise.all(deletePromises)
                    .then(() => {
                        console.log("All images and thumbnails deleted successfully.");
                    })
                    .catch((error) => {
                        console.log("An error occurred during the deletion process:", error);
                    });
            }
            

            await dispatch(deleteNewsItem(newsItem));
        }
        catch (error) {
            console.error('Error deleting newsItem:', error);

        } finally {
            setIsProgressing(false);
            handleClose();
        }
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Confirm?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Do you confirm delete this record?</h6>
                <p className="text-danger">
                    {message}
                </p>
            </Modal.Body>
            <Modal.Footer>

                {isProgressing ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Button variant="secondary" className='me-2' onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button variant="primary" className='me-2' onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteNewsModal;