import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormGroup } from 'react-bootstrap';
import './cropperStyles.css';
import countryList from 'react-select-country-list';
import { partnerTypes } from '../../../Consts';

const PartnerSelectModal = ({ show, handleClose, handleCallback }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [formValues, setFormValues] = useState({
    organisation: "",
    partnerType: "",
    countryCode: "",
    venue: ""
  });

  const countries = countryList().getData();

  const handleSubmit = (event) => {
    event.preventDefault();

    handleCallback(formValues);
    setFormValues({
      organisation: "",
      partnerType: "",
      countryCode: "",
      venue: ""
    });
    handleClose();
  }

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: value
    })
  };

  useEffect(() => {
    //console.log("state", formValues);
  }, [formValues]);



  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='md'>
      <Form onSubmit={handleSubmit} className='bg-light'>
        <Modal.Header closeButton>
          <Modal.Title>Add Project Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label><strong>Organisation *</strong></Form.Label>
            <Form.Control
              id="organisation"
              type="text"
              placeholder="Enter partner organisation title"
              maxLength='100'
              value={formValues.organisation}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <br />

          <FormGroup>
            <Form.Label><strong>Partner Type *</strong></Form.Label>
            <Form.Select
              id="partnerType"
              aria-label="Select Partner Type"
              value={formValues.partnerType}
              onChange={handleChange} // Handle select change
              required
            >
              <option value="">Please select an item</option>
              {partnerTypes.map((ptype, index) => (
                <option key={index} value={index}>{ptype}</option>
              ))}
            </Form.Select>
          </FormGroup>
          <br />

          <FormGroup>
            <Form.Label><strong>Partner Country *</strong></Form.Label>
            <Form.Select
              id="countryCode"
              aria-label="Select Partner Type"
              onChange={handleChange}
              required
            >
              <option value="">Please select an item</option>
              {countries.map(country => (
                <option key={country.value} value={country.value}>{country.label}</option>
              ))}
            </Form.Select>

          </FormGroup>
          <br />

          <FormGroup>
            <Form.Label><strong>Venue *</strong></Form.Label>
            <Form.Control
              id="venue"
              type="text"
              placeholder="Enter partner's venue"
              maxLength='100'
              value={formValues.venue}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <br />


          {errorMessage && <p className="text-danger">{errorMessage}</p>}


        </Modal.Body>
        <Modal.Footer>

          <div>
            <Button variant="secondary" onClick={handleClose} className='me-2'>
              Cancel
            </Button>

            <Button variant="primary" className='me-2' type="submit">
              Add Partner
            </Button>
          </div>

        </Modal.Footer>
      </Form>
    </Modal>

  );
};

export default PartnerSelectModal;