import CircularProgress from "./CircularProgress";


const CenteredCircularProgress = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress /> <span className='ms-2'>Loading...</span>
        </div>
    )
}

export default CenteredCircularProgress;