import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ImageGalleryModal.css'; // Import custom CSS

const ImageGalleryModal = ({ show, handleClose, newsImages, currentIndex }) => {
    const [currentIndexState, setCurrentIndexState] = useState(currentIndex);

    useEffect(() => {
        setCurrentIndexState(currentIndex);
    }, [currentIndex]);
    

    const handlePrevious = () => {
        const newIndex = (currentIndexState - 1 + newsImages.length) % newsImages.length;
        setCurrentIndexState(newIndex);
    };

    const handleNext = () => {
        const newIndex = (currentIndexState + 1) % newsImages.length;
        setCurrentIndexState(newIndex);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
            handlePrevious();
        } else if (event.key === 'ArrowRight') {
            handleNext();
        }
    };

    const handleCloseProcess = () => {
        handleClose();
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, currentIndexState]);

    return (
        <Modal show={show} onHide={handleCloseProcess} backdrop="static" keyboard={true} size="lg" className="image-gallery-modal">
            <Modal.Header closeButton>
                <Modal.Title>Image Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="image-container">
                    <div className="image-wrapper">
                        <img src={newsImages[currentIndexState]} className="gallery-image" alt="Current" />
                        <div className="image-counter">
                            {currentIndexState + 1} / {newsImages.length}
                        </div>
                        <Button variant="light" className="image-nav prev" onClick={handlePrevious}>
                            &lt;
                        </Button>
                        <Button variant="light" className="image-nav next" onClick={handleNext}>
                            &gt;
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCloseProcess}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ImageGalleryModal;
