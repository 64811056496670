export const createProject = (item, recordId) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirebase().firestore();

        firestore.collection('/projects')
            .doc(recordId)
            .set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                ...item
            }).then(() => {
                dispatch({ type: 'CREATE_PROJECT', item });
                console.log("Project successfully added");
            }).catch((err) => {
                dispatch({ type: 'CREATE_PROJECT_ERROR', err });
                console.log(err);

            })
    }
}

export const updateProject = (item, projectId) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirebase().firestore();
        
        firestore.collection('/projects')
            .doc(projectId)
            .update(
                {
                    updated: firebase.firestore.FieldValue.serverTimestamp(),
                    ...item
                },
                { merge: true }
            ).then(() => {
                dispatch({ type: 'UPDATE_PROJECT', item });
                console.log("Project successfully updated: ");
            }).catch((err) => {
                dispatch({ type: 'UPDATE_PROJECT_ERROR', err });
                console.log(err);

            })
    }
}

export const deleteProject = (item) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirebase().firestore();

        firestore.collection('/projects')
            .doc(item.id)
            .delete()
            .then(() => {
                dispatch({ type: 'DELETE_PROJECT', item });
                console.log("project successfully deleted");
            }).catch((err) => {
                dispatch({ type: 'DELETE_PROJECT_ERROR', err });
                console.log(err);
            })
    }
}

export const checkProjectReferences = (projectId) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirebase().firestore();
        let referencesExist = false;

        try {
            // Example check: Look for documents in another collection that reference this project
            const snapshot = await firestore.collection('news')
                .where('projectId', '==', projectId)
                .get();

            referencesExist = !snapshot.empty;
        } catch (error) {
            console.error("Error checking project references:", error);
        }

        return referencesExist;
    };
};