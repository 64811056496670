import React, { Component, useState } from 'react';
import { Breadcrumb, Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import BottomSide from '../components/BottomSide';
import NewsImageComponent from './NewsImageComponent';
import ImageGalleryModal from './ImageGalleryModal';
import { Helmet } from 'react-helmet';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import axios from 'axios';

class NewsItemDetailPage extends Component {
    _isMounted = false;

    state = {
        newsItem: null,
        loading: true,
        error: null,
    };

    async fetchData() {
        this.setState({ loading: true });

        const { slug } = this.props.match.params;

        const params = { itemId: slug };


        try {
            const response = await axios.get('https://us-central1-bycbase-c9b76.cloudfunctions.net/fetchNewsItem', { params });

            const fetchedItem = response.data;

            this.setState({
                newsItem: fetchedItem,
                loading: false,
            });

        } catch (error) {
            console.error('Error fetching newsItem:', error);
            this.setState({ error: error.message, loading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        const currentUrl = window.location.href;
        const { newsItem, loading, error } = this.state;

        if (loading) {
            return <CenteredCircularProgress />;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        const metaTitle = (newsItem.title ? newsItem.title : "News Detail") + " - Bright Youth Community";
        const metaDescription = newsItem.content ? newsItem.content.substring(0, 155) : "Find the latest news and updates on our organization's events and projects here. Stay informed about developments and join us.";

        return (

            <div>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <link rel="canonical" href={currentUrl} />

                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={metaTitle} />
                    <meta name="twitter:description" content={metaDescription} />
                    <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                </Helmet>

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/news">News</Breadcrumb.Item>
                        <Breadcrumb.Item active>{newsItem?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container className="mt-4 bg-light" style={{ minHeight: '90px' }}>
                    <Row>
                        <Col xs={12}>
                            <h2>{newsItem?.title}</h2>
                            <small className="text-muted" style={{ textAlign: 'right' }}>{new Date(newsItem.createdDate).toLocaleDateString()}</small>


                            <span> { } </span>
                        </Col>
                    </Row>

                </Container>

                <NewsItemFeaturedImage newsItem={newsItem} />

                <div>
                    <BottomSide />
                </div>



            </div>
        )
    }
}

export default NewsItemDetailPage


const NewsItemFeaturedImage = ({ newsItem }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
    const [currentImageIdx, setCurrentImageIdx] = useState(null)

    const cleanHTML = DOMPurify.sanitize(newsItem.content);

    const handleOnSubImageClickCallback = (index) => {
        setCurrentImageIdx(index);
        setShowImageGalleryModal(true);
    }

    const handleCloseImageGalleryModal = () => {
        setShowImageGalleryModal(false);
    }

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <Container className="mt-4 mb-5">
            <div>

                <div className="d-flex  align-items-center" style={{position: 'relative' }}>
                    <Image
                        src={newsItem.featuredImageUrl ? newsItem.featuredImageUrl : "/imgs/news-featured.jpg"}
                        fluid
                        onLoad={handleImageLoad}
                    />
                    {isLoading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Spinner animation="border" role="status">
                                <span
                                    className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>

                    )}
                </div>

                <div className='mt-4 mb-4' dangerouslySetInnerHTML={{ __html: cleanHTML }} />

                <div className="d-flex flex-row flex-wrap">
                    {
                        newsItem?.newsImagesThumbUrls?.map((imageUrl, index) => (

                            <div key={index}>
                                <NewsImageComponent
                                    imageName={imageUrl}
                                    index={index}
                                    handleOnClickCallback={handleOnSubImageClickCallback}
                                />
                            </div>
                        ))
                    }
                </div>

            </div>

            {
                newsItem?.newsImagesUrls &&
                <ImageGalleryModal
                    show={showImageGalleryModal}
                    handleClose={handleCloseImageGalleryModal}
                    newsImages={newsItem?.newsImagesUrls}
                    currentIndex={currentImageIdx}
                />
            }

        </Container>

    );
}