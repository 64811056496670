import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../config/fbConfig';
import { Image } from 'react-bootstrap';

const ProjectLogoFromStorage = ({ imageName, path }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const pathAndFile = path + '/' + imageName;

    useEffect(() => {
        // Function to get download URL from Firebase Storage
        const getDownloadUrl = async () => {
            try {
                const imageRef = ref(storage, pathAndFile);
                const url = await getDownloadURL(imageRef);
                setImageUrl(url);
            } catch (error) {
                console.error('Error getting download URL:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (imageName) {
            getDownloadUrl();
        } else {
            setIsLoading(false);
        }

    }, [pathAndFile]); // Run this effect when logoPath changes



    return (
        <div>
            {
                isLoading ? (
                    <div className="spinner-border text-primary" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </div >
                ) : (
                    <Image
                        id="projectLogo"
                        src={imageUrl}
                        thumbnail
                        width={180}
                        height={180}
                    />
                )
            }
        </div >
    )

}

export default ProjectLogoFromStorage;