import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
import NewsListComponent from './NewsListComponent';
import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import axios from 'axios';


class NewsPage extends Component {
    _isMounted = false;

    state = {
        news: [],
        loading: true,
        error: null,
        lastCreated: null,
        hasMore: true,
    };

    async fetchNewsData() {
        this.setState({ loading: true });

        const newsLimit = 8;

        const { lastCreated } = this.state;
        const params = { limit: newsLimit };

        if (lastCreated) {
            params.lastCreated = lastCreated;
        }


        try {
            const response = await axios.get('https://us-central1-bycbase-c9b76.cloudfunctions.net/fetchNews', { params });

            const fetchedNews = response.data.news;
            const lastCreatedTimestamp = response.data.lastCreated;

            this.setState(prevState => ({
                news: [...prevState.news, ...fetchedNews],
                loading: false,
                lastCreated: lastCreatedTimestamp,
                hasMore: fetchedNews.length % newsLimit === 0,
            }));
        } catch (error) {
            console.error('Error fetching news:', error);
            this.setState({ error: error.message, loading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchNewsData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLoadMore = () => {
        this.fetchNewsData();
    };

    render() {
        const currentUrl = window.location.href;

        const { hasMore, loading, error, news } = this.state;

        if (loading && news.length === 0) {
            return <CenteredCircularProgress />;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>

                <Helmet>
                    <title>News - Bright Youth Community</title>
                    <meta name="description" content="Find the latest news and updates on our organization's events and projects here. Stay informed about developments and join us." />
                    <link rel="canonical" href={currentUrl} />

                    <meta property="og:title" content="News - Bright Youth Community" />
                    <meta property="og:description" content="Find the latest news and updates on our organization's events and projects here. Stay informed about developments and join us." />
                    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="News - Bright Youth Community" />
                    <meta name="twitter:description" content="Find the latest news and updates on our organization's events and projects here. Stay informed about developments and join us." />
                    <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                </Helmet>

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>News</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container className="mt-4">
                    <Row>
                        <Col xs={12}>
                            <h2>News</h2>
                        </Col>
                    </Row>

                </Container>

                <NewsListComponent news={news} />

                <Container className="mt-4 mb-4">
                    <Row>
                        <Col xs={12} className="text-center">
                            {loading && news.length > 0 && (
                                <CenteredCircularProgress />
                            )}
                            {!loading && hasMore && (
                                <Button onClick={this.handleLoadMore} variant="primary">Load More</Button>
                            )}
                            {/* 
                            {!hasMore && !loading && (
                                <p>No more news available.</p>
                            )} */}
                        </Col>
                    </Row>
                </Container>

                <div>
                    <BottomSide />
                </div>


            </div >

        )
    }
}

export default NewsPage;