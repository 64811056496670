const initialState = {
  projects: [],
  error: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CREATE_PROJECT':
      return {
        ...state,
        projects: [...state.projects, action.item], 
        error: null, // Clear any previous error
      };

    case 'UPDATE_PROJECT':
      return {
        ...state,
        projects: [...state.projects, action.item], // Add new news item to the state
        error: null, // Clear any previous error
      };

    case 'DELETE_PROJECT':
      //console.log('AAA Deleted news with id:', action.newsItem.id);
      return {
        ...state,
        projects: state.projects.filter((item) => item.id !== action.project.id), // Remove deleted news item
        error: null, // Clear any previous error
      };

    case 'CREATE_PROJECT_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    case 'UPDATE_PROJECT_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    case 'DELETE_PROJECT_ERROR':
      return {
        ...state,
        error: action.err, // Store the error
      };

    default:
      return state;
  }
};

export default projectReducer;