import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ReactCountryFlag from "react-country-flag";
import { partnerTypes } from "../../../Consts";

const ProjectPartnerComponent = ({ partner, handleDelete }) => {

    return (


        <div className="ms-2 mb-2" style={{ position: 'relative', width: '300px', height: '90px', border: '1px solid blue' }}>


            <div className="m-2">
                <span>{partner.organisation}</span>
            </div>

            <div className="ms-2 me-2">
                <span className="d-flex align-items-center">
                    <ReactCountryFlag
                        countryCode={partner.countryCode}
                        svg
                        style={{
                            fontSize: '2em',
                            lineHeight: '2em',
                        }}
                    />
                    <small className='ms-2'>{partnerTypes[partner.partnerType]}</small> 
                </span>


            </div>
            <AiOutlineClose
                size={24}
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    cursor: 'pointer',
                    color: 'blue',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '5px'
                }}
                onClick={() => handleDelete(partner)}
            />

        </div>

    );
}

export default ProjectPartnerComponent;