import React, { Component } from 'react';
import { Form, Button, FormGroup, FormControl, FormLabel, Col, Row } from 'react-bootstrap';
import './Login.css';
import BottomSide from '../components/BottomSide';
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: null,
    };
  }

  handleChange = (e) => {
    //console.log(e.target);
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.signIn(this.state)
  }

  handleSubmit2 = (event) => {
    event.preventDefault();
  };

  render() {
    const { email, password, errorMessage } = this.state;
    const { authError, auth, signInWithGoogle } = this.props;

    if (auth.uid)
      return <Redirect to="/admin" />;

    return (
      <div>


        <div className="login-form">

          <Form onSubmit={this.handleSubmit}>
           
            <FormGroup>
              <FormLabel>Email address</FormLabel>
              <FormControl
                type="email"
                id="email"
                value={email}
                onChange={this.handleChange}
                placeholder="Enter email"
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Password</FormLabel>
              <FormControl
                type="password"
                id="password"
                value={password}
                onChange={this.handleChange}
                placeholder="Password"
                required
              />
            </FormGroup>
            <br />
            <Row className='align-center'>
              <Col>
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </Col>
            </Row>


          </Form>
          <br />

          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>

        <BottomSide />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);