export const createNews = (newsItem) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirebase().firestore();

        firestore.collection('/news')
            .doc()
            .set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                ...newsItem
            }).then(() => {
                dispatch({ type: 'CREATE_NEWS', newsItem });
                console.log("news successfully added");
            }).catch((err) => {
                dispatch({ type: 'CREATE_NEWS_ERROR', err });
                console.log(err);

            })
    }
}

export const updateNews = (newsItem) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirebase().firestore();

        firestore.collection('/news')
            .doc(newsItem.id)
            .update(
                {
                    updated: firebase.firestore.FieldValue.serverTimestamp(),
                    ...newsItem
                },
                { merge: true }
            ).then(() => {
                dispatch({ type: 'UPDATE_NEWS', newsItem });
                console.log("news successfully updated");
            }).catch((err) => {
                dispatch({ type: 'UPDATE_NEWS_ERROR', err });
                console.log(err);

            })
    }
}

export const deleteNewsItem = (newsItem) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirebase().firestore();
        //const store = getFirebase().store();

        firestore.collection('/news')
            .doc(newsItem.id)
            .delete()
            .then(() => {
                dispatch({ type: 'DELETE_NEWS', newsItem });
                console.log("news successfully deleted");
            }).catch((err) => {
                dispatch({ type: 'DELETE_NEWS_ERROR', err });
                console.log(err);

            })
    }
}


export const createQuestions = (questions, courseId, testId) => {

    return (dispatch, getState, { getFirebase, getFirestore }) => {

        let count = Object.keys(questions).length;

        if (count === 0) {

            const firestore = getFirebase().firestore();

            const batch = firestore.batch()

            let question = {
                questionType: null,
                questionText: null,
                questionImgUrl: null,
                solutionDesc: null,
                optionTextA: null,
                optionTextB: null,
                optionTextC: null,
                optionTextD: null,
                optionTextE: null,
                optionUrlA: null,
                optionUrlB: null,
                optionUrlC: null,
                optionUrlD: null,
                optionUrlE: null,
                solution: null
            }

            let i;
            for (i = 1; i < 16; i++) {
                let docId = (100 + i).toString()
                const docRef = firestore.collection('/courses/' + courseId + '/tests/' + testId + '/questions').doc(docId)
                batch.set(docRef, question)
            }

            batch.commit()
                .then(() => {
                    dispatch({ type: 'ADD_QUESTIONS_SUCCESS' });
                }).catch((err) => {
                    dispatch({ type: 'ADD_QUESTIONS_ERROR', err });
                })

        } else {
            alert("soru olduğundan oluşturulmadı")
            //dispatch({ type: 'CREATE_TEST_MATCH', test });
        }
    }
}