import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { storage } from '../../config/fbConfig'
import { ref, deleteObject } from 'firebase/storage';
import CircularProgress from '../../components/common/CircularProgress';
import { useDispatch } from 'react-redux';
import { deleteProject, checkProjectReferences } from '../../store/actions/projectActions';

const DeleteProjectModal = ({ show, handleClose, message, pItem }) => {

    const [isProgressing, setIsProgressing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const handleBeforeClose = () => {
        setErrorMessage("");
        handleClose();
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        setIsProgressing(true); // Show progress indicator

        try {

            const referencesExist = await dispatch(checkProjectReferences(pItem.id));

            if (referencesExist) {
                setErrorMessage('This project is being used in other records and cannot be deleted.');
                setIsProgressing(false);
                return;
            }

            if (pItem.projectLogo) {
                const LogoImageRef = ref(storage, "projects/" + pItem.projectLogo);
                const logoImageThumbRef = ref(storage, "projects/thumb_" + pItem.projectLogo);

                try {
                    await deleteObject(LogoImageRef);
                    console.log("Logo image deleted");
                } catch (error) {
                    console.log("Error deleting logo image:", error);
                }

                try {
                    await deleteObject(logoImageThumbRef)
                    console.log("Logo image thumbnail deleted");
                } catch (error) {
                    console.log("Error deleting logo image thumbnail:", error);
                }

            }

            await dispatch(deleteProject(pItem));
            handleBeforeClose();
        }
        catch (error) {
            console.error('Error deleting project:', error);

        } finally {
            setIsProgressing(false);
        }
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Confirm?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Do you confirm delete this Record?</h6>
                <p className="text-danger">
                    {message}
                </p>
                {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                )}
            </Modal.Body>
            <Modal.Footer>

                {isProgressing ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Button variant="secondary" className='me-2' onClick={handleBeforeClose}>
                            Cancel
                        </Button>

                        <Button variant="primary" className='me-2' onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteProjectModal;