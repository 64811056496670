import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

const NewsImageComponent = ({ imageName, index, handleOnClickCallback }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (

        <div className="me-2 mb-2" style={{ position: 'relative', height: '180px' }}>
            
                <Image
                    src={imageName}
                    alt=""
                    thumbnail
                    onLoad={handleImageLoad}
                    className="news-image"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOnClickCallback(index)}
                />
                {isLoading && (
                    <div className="spinner-border text-primary" role="status" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )}
            
        </div>

    );
}

export default NewsImageComponent;