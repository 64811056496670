import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import { BsPlug } from "react-icons/bs";
import { IoFootstepsOutline } from "react-icons/io5";
import { FiHome } from "react-icons/fi";
import FlyingImages from './FlyingImages';
import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';

class MainPage extends Component {

    componentDidMount() {
        console.log("firestoreConnect status:", this.props.firestoreStatus);
        console.log("Projects collection:", this.props.projects);
    }

    render() {
        const currentUrl = window.location.href;

        return (
            <div>
                <Helmet>
                    <title>Bright Youth Community (Parlak Gençlik Topluluğu Derneği)</title>
                    <link rel="canonical" href={currentUrl} />

                    <meta property="og:title" content="Bright Youth Community [Parlak Gençlik Topluluğu Derneği]" />
                    <meta property="og:description" content="We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields." />
                    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Bright Youth Community [Parlak Gençlik Topluluğu Derneği]" />
                    <meta name="twitter:description" content="We are dedicated to projects that promote environmental conservation and empower the younger generation to excel in various fields." />
                    <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                </Helmet>

                <Banner />

                <Container className='mt-4 mb-4'>
                    <Row>
                        <Col xs={12} md={5}>
                            <Row className='mb-4 mt-4'>
                                <Col className="text-center mt-4 mb-4">
                                    <h1>Our Mission</h1>
                                </Col>

                            </Row>
                            <div className="d-flex align-items-center mt-4">
                                <blockquote className="blockquote">
                                    <footer className="blockquote-footer">Dear Youth, Youth Workers, and Educators, We will be delighted to have you among us. Let's build the future together! With love and respect,</footer>
                                </blockquote>

                            </div>

                            <Row>
                                <Col className='text-center' xs={12} sm={12} md={4} lg={4}>
                                    <FiHome size={96} className="me-2" color='green' opacity={0.5} />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className="box-icon-caption">
                                        <h2>Main mission</h2>
                                        <p>We are here to protect environment, improve our young community’s well being and develop their skills and knowledge required in the 21st century!</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='text-center' xs={12} sm={12} md={4} lg={4}>
                                    <BsPlug size={96} className="me-2" color='green' opacity={0.5} />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className="box-icon-caption">
                                        <h2>Innovative Projects</h2>
                                        <p>Our association develops projects that encompass digital skills, creative thinking, communication, problem-solving, and much more.</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='text-center' xs={12} sm={12} md={4} lg={4}>
                                    <IoFootstepsOutline size={96} className="me-2" color='green' opacity={0.5} />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className="box-icon-caption">
                                        <h2>Steady Steps</h2>
                                        <p>Together with you, we strive to become the empowered and conscious youth of the future. By combining our imagination, passion, and energy, we organize activities that benefit our surroundings and society. Are you ready for a journey filled with international youth exchanges, training sessions, seminars, and voluntary events?</p>
                                    </div>
                                </Col>
                            </Row>



                        </Col>

                        <Col xs={12} md={1} />

                        <Col xs={12} md={6} style={{ position: 'relative', height: '700px' }}>
                            <FlyingImages />
                        </Col>





                    </Row>
                </Container>

                <Image
                    className="d-block w-100 mt-4"
                    src="imgs/parallax-img-2.jpg"
                    alt="BYC"
                    fluid
                    style={{
                        height: '360px', // İstediğiniz yükseklik
                        objectFit: 'cover', // Resmi kırparak kapsayıcıya sığdırır
                        objectPosition: 'center', // Resmi ortalar       
                        opacity: 0.7
                    }}
                />

                <BottomSide />


            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        projects: state.firestore.ordered.projects,
        auth: state.firebase.auth
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'projects' }
    ])
)(MainPage)



const Banner = () => {
    return (
        <div className='mb-4'>
            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src="imgs/bg-bunner-2.jpg"
                        alt="BYC"
                        fluid
                        style={{
                            height: '450px', // İstediğiniz yükseklik
                            objectFit: 'cover', // Resmi kırparak kapsayıcıya sığdırır
                            objectPosition: 'center', // Resmi ortalar                                
                        }}
                    />
                    <Carousel.Caption>
                        <h1>Bright Youth Community</h1>
                        <p className='display-6'>By combining our imagination, passion, and energy, we organize activities that benefit our surroundings and society.</p>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>
        </div>
    );
};