import React, { Component } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import ProjectDetailsComponent from './ProjectDetailsComponent'
import WebLinksComponent from './WebLinksComponent'
import ReactCountryFlag from "react-country-flag";
import { owners } from '../../Consts'
import BottomSide from '../components/BottomSide'
import { Helmet } from 'react-helmet';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import axios from 'axios';

class ProjectDetailsPage extends Component {
    _isMounted = false;

    state = {
        project: [],
        loading: true,
        error: null,        
    };

    async fetchItemsData() {
        this.setState({ loading: true });

        const { projectId } = this.props.match.params;

        const params = { itemId: projectId };


        try {
            const response = await axios.get('https://us-central1-bycbase-c9b76.cloudfunctions.net/fetchProject', { params });

            const fetchedItem = response.data;

            this.setState({
                project: fetchedItem,
                loading: false,
            });

        } catch (error) {
            console.error('Error fetching projects:', error);
            this.setState({ error: error.message, loading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchItemsData ();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProjectOwnerName = (ownerId) => {
        const ownerIndex = parseInt(ownerId);
        return owners[ownerIndex] || "undefined";
    }

    render() {
        const currentUrl = window.location.href;
        const { loading, error } = this.props;
        const { project } = this.state;

        if (loading) {
            return <CenteredCircularProgress />;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        const metaTitle = (project.title ? "Project: " + project.title : "Projects - Bright Youth Community");
        const metaDescription = project.description ? project.description.substring(0, 155) : "Welcome to our page featuring the projects our association is involved in and the developments related to these projects. This page is like an exhibition showcasing our areas of work.";

        return (
            <div>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <link rel="canonical" href={currentUrl} />

                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={metaTitle} />
                    <meta name="twitter:description" content={metaDescription} />
                    <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                </Helmet>
                

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
                        <Breadcrumb.Item active>{project?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container className="mt-4 bg-light" style={{ minHeight: '90px' }}>
                    <Row>
                        <Col xs={12}>
                            <h2>{project?.title}</h2>
                            <ReactCountryFlag
                                countryCode="TR"
                                svg
                                style={{
                                    fontSize: '2em',
                                    lineHeight: '2em',
                                }}
                            />
                            <span> {owners[project?.owner]} </span>
                        </Col>
                    </Row>
                    <WebLinksComponent project={project} />

                </Container>

                <ProjectDetailsComponent
                    project={project}                    
                />

                <div className='mb-5'>
                    <BottomSide />
                </div>

            </div>
        )
    }
}

export default ProjectDetailsPage;