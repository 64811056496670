import React, { Component } from 'react';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';
import { FaEdit } from "react-icons/fa";
import { RiAddBoxLine, RiDeleteBin5Fill } from "react-icons/ri";
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import AdminPanelMenuBar from '../Admin/AdminPanelMenuBar';
import BottomSide from '../components/BottomSide';
import { signOut } from '../../store/actions/authActions';
import DeleteProjectModal from './DeleteProjectModal';
import { Helmet } from 'react-helmet';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import { projectStatuses } from '../../Consts';

class ProjectsManagementPage extends Component {

    state = {
        currentPage: 1,
        itemsPerPage: 20,
        willDelete: null,
        showDeleteModal: false,

    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    componentDidMount() {
        const { match } = this.props;
        const { pageNumber } = match.params;
        if (pageNumber) {
            this.setState({ currentPage: parseInt(pageNumber, 20) });
        }
    }

    handleDeleteCallback = (newsItem) => {
        //const storage = 
        this.setState({
            willDelete: newsItem,
            showDeleteModal: true,
            //willDeleteStorage:
        })

    }

    handleCloseDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        })
    }

    render() {


        const { auth, projects } = this.props;
        const { currentPage, itemsPerPage } = this.state;

        if (!auth.uid)
            return <Redirect to="/login" replace />;

        // Check if news is undefined
        if (!projects) {
            return <CenteredCircularProgress />;
        }

        // Calculate pagination values
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentProjects = projects.slice(indexOfFirstItem, indexOfLastItem);

        // Render pagination items
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(projects.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (

            <div>
                <Helmet>
                    <title>Manage Projects - Bright Youth Community</title>
                </Helmet>

                <AdminPanelMenuBar />

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Admin Panel</Breadcrumb.Item>
                        <Breadcrumb.Item active>Manage Projects</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <ManageProjectListComponent
                    projects={currentProjects}
                    handleDeleteCallback={this.handleDeleteCallback}
                />

                {/* Pagination controls */}
                <Container className="mt-5 mb-5">
                    <Row>
                        <Col xs={8}>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {/* Previous button */}
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <Link to={`/manage_projects/page/${currentPage - 1}`} className="page-link" onClick={() => this.handlePageChange(currentPage - 1)}>Previous</Link>
                                    </li>

                                    {/* Page numbers */}
                                    {pageNumbers.map((number) => (
                                        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                            <Link to={`/manage_projects/page/${number}`} className="page-link" onClick={() => this.handlePageChange(number)}>
                                                {number}
                                                {number === currentPage && <span className="sr-only"></span>}
                                            </Link>
                                        </li>
                                    ))}

                                    {/* Next button */}
                                    <li className={`page-item ${currentPage === Math.ceil(projects.length / itemsPerPage) ? 'disabled' : ''}`}>
                                        <Link to={`/manage_projects/page/${currentPage + 1}`} className="page-link" onClick={() => this.handlePageChange(currentPage + 1)}>Next</Link>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                        <Col xs={4} className="text-end">
                            <p>Page {currentPage} of {Math.ceil(projects.length / itemsPerPage)}</p>
                        </Col>
                    </Row>
                </Container>

                <BottomSide />

                <DeleteProjectModal
                    show={this.state.showDeleteModal}
                    handleClose={this.handleCloseDeleteModal}
                    message={"News Title: " + this.state.willDelete?.title}
                    pItem={this.state.willDelete}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        projects: state.firestore.ordered.projects,        

    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        /* { collection: 'projects', }, */
        { collection: 'projects', orderBy: ['created', 'desc'] },

    ])
)(ProjectsManagementPage);



const ManageProjectListComponent = ({ projects, handleDeleteCallback }) => {

    return (
        <>
            <Container className='mt-1 mb-5'>
                <Row>
                    <Col sm={12}>
                        <h2 className="mt-4 mb-4">Manage Projects</h2>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} className='d-flex justify-content-end mb-2'>
                        <RiAddBoxLine size={24} color='blue' className='me-1' />
                        <Link to="/add_project" className="text-end">Add Project</Link>
                    </Col>
                </Row>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>P.No</th>
                            <th>Status</th>
                            <th>Project Name</th>
                            <th>Start-End Date</th>
                            <th>User</th>
                            <th>Project Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects && projects.map((pItem, index) => (
                            <tr key={pItem.id}>
                                <td>{pItem.id}</td>
                                <td>{projectStatuses[pItem?.projectStatus]}</td>
                                <td>{
                                    <a href={`/project/${pItem.id}`} target="_blank" rel="noopener noreferrer">
                                        {pItem.title}
                                    </a>}
                                </td>
                                <td>{pItem.startDate} - {pItem.endDate}</td>
                                <td>{"User"}</td>
                                <td>{pItem.projectType}</td>
                                <td className='align-end'>
                                    <Link to={`/update_project/${pItem.id}`}>
                                        <FaEdit size={18} className="me-2" color='blue' />
                                    </Link>

                                    <RiDeleteBin5Fill
                                        size={18}
                                        className="ms-2"
                                        color='blue'
                                        cursor="pointer"
                                        onClick={() => handleDeleteCallback(pItem)}
                                    />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </Container>

        </>

    );
}