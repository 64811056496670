import React, { Component, createRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/authActions';
import { Redirect } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import { Breadcrumb, Button, Col, Container, Form, FormGroup, Row, Image } from 'react-bootstrap';
import BottomSide from '../../components/BottomSide';
import { createNews } from '../../../store/actions/newsActions';
import { generateSlug } from '../../../utils/generateSlug';
import { RiImageAddFill } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import { storage } from '../../../config/fbConfig';
import './cropperStyles.css';
import ImageSelectModal from './ImageSelectModal';
import ImageDeleteModal from './ImageDeleteModal';
import { ref, uploadBytes } from 'firebase/storage';
import CircularProgress from '../../../components/common/CircularProgress';
import { getResizedImg, generateImageName, readFile } from '../../../utils/canvasUtils';
import StorageImageComponent from './StorageImageComponent';
import CacheImageComponent from './CacheImageComponent';
import { Helmet } from 'react-helmet';

const CKEditorComponent = lazy(() => import('../../../components/common/CKEditorComponent'));

class AddNewsPage extends Component {

    constructor(props) {
        super(props);
        this.fileInputRef = createRef();
    }

    state = {
        title: '',
        projectId: '',
        slug: '',
        content: '',
        featuredImage: '',
        newsImages: [],
        newsImagesCache: [],
        errorMessage: null,
        croppedImage: null,
        croppedImageThumb: null,
        showImageSelectModal: false,
        showDeteleModal: false,
        isSubImagesProgress: false,
        isSubmitting: false
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === "title")
            this.setState({
                slug: generateSlug(e.target.value)
            });
    }

    handleEditorChange = (data) => {
        this.setState({ content: data });
    }

    handleSubmit = async (e) => {

        const { croppedImage, croppedImageThumb, newsImagesCache } = this.state;

        if (this.state.content === '') {
            this.setState({ errorMessage: "Content required" });
            e.preventDefault();
        }
        else {
            this.setState({ isSubmitting: true });

            e.preventDefault();
            try {
                const promises = [];

                const featuredImageName = generateImageName(croppedImage.type)

                //featured ipage uploading
                const storageRef = ref(storage, "news/" + featuredImageName);
                promises.push(uploadBytes(storageRef, croppedImage));
                //featured image thumb uploading
                const storageRefThumb = ref(storage, "news/thumb_" + featuredImageName);
                promises.push(uploadBytes(storageRefThumb, croppedImageThumb));

                for (let i = 0; i < newsImagesCache.length; i++) {
                    const imageItem = newsImagesCache[i];

                    const imageRef = ref(storage, "news/" + imageItem.name);
                    promises.push(uploadBytes(imageRef, imageItem.image));

                    const thumbRef = ref(storage, "news/thumb_" + imageItem.name);
                    promises.push(uploadBytes(thumbRef, imageItem.thumb));
                }

                await Promise.all(promises);

                const newsItem = {
                    title: this.state.title,
                    projectId: this.state.projectId,
                    slug: this.state.slug,
                    content: this.state.content,
                    featuredImage: featuredImageName,
                    newsImages: this.state.newsImagesCache.map(image => image.name)
                }

                this.props.createNewsItem(newsItem);
                this.props.history.push('/manage_news')

            } catch (error) {
                console.error(error)
            } finally {
                this.setState({ isSubmitting: false });
            }

        }
    }
    handleSubImagesIcon = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    }

    handleShowImageSelectModal = () => { this.setState({ showImageSelectModal: true }) }
    handleCloseImageSelectModal = () => { this.setState({ showImageSelectModal: false }) }

    handleShowDeleteModal = () => {
        this.setState({
            featuredImage: '',
            croppedImage: '',
            croppedImageThumb: ''
        });
    }
    handleCloseDeleteModal = () => { this.setState({ showDeleteModal: false }) }
    handleOnImageDeleted = () => { this.setState({ croppedImage: null }) }

    onSubImagesFilesChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {

            const files = e.target.files;

            const filePromises = [];

            this.setState({ isSubImagesProgress: true });



            try {
                for (let i = 0; i < files.length; i++) {
                    filePromises.push(readFile(files[i]));
                }

                const fileResults = await Promise.all(filePromises);

                const resizednewsImage = [];

                for (let i = 0; i < fileResults.length; i++) {
                    const resizedImage = await getResizedImg(fileResults[i], 1024, 576);
                    const resizedImageThumb = await getResizedImg(fileResults[i], 320, 180);

                    const imageName = generateImageName(resizedImage.type)
                    resizednewsImage.push({ image: resizedImage, thumb: resizedImageThumb, name: imageName });
                }

                this.setState((prevState) => ({
                    newsImagesCache: [...prevState.newsImagesCache, ...resizednewsImage],
                }));

            } catch (error) {
                console.error('Error reading files:', error);
            } finally {
                this.setState({ isSubImagesProgress: false });
            }
        }
    }

    handleDeleteNewsImagesCacheByIndex = ({ index }) => {
        const updatedNewsImagesCache = [...this.state.newsImagesCache];

        updatedNewsImagesCache.splice(index, 1);

        this.setState({
            newsImagesCache: updatedNewsImagesCache
        });
    }

    render() {

        const { auth, signOut, projects } = this.props;
        const { title, projectId, slug, content, errorMessage, newsImages, newsImagesCache, isSubImagesProgress } = this.state;

        if (!auth.uid)
            return <Redirect to="/login" replace />;

        return (
            <div>
                <Helmet>
                    <title>Add News - Bright Youth Community</title>
                </Helmet>

                <Navbar bg="dark" variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand href="#">Admin Panel</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="/admin  ">Dashboard</Nav.Link>
                                <Nav.Link href="/manage_news">News</Nav.Link>
                                <Nav.Link href="/manage_projetcs">Projects</Nav.Link>
                                <Nav.Link href="#" onClick={signOut}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Admin Panel</Breadcrumb.Item>
                        <Breadcrumb.Item href="/manage_news">Manage News</Breadcrumb.Item>
                        <Breadcrumb.Item active>Add News</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container>
                    <h1>Add News</h1>
                    <Form onSubmit={this.handleSubmit} className='bg-light'>

                        <div className='m-5'>

                            <br />
                            <Form.Group>

                                <Form.Label><strong>Featured Image</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">
                                    <div>

                                        <Image
                                            id="featuredImg"
                                            src={this.state.croppedImage ? URL.createObjectURL(this.state.croppedImage) : "/imgs/news-featured.jpg"}
                                            thumbnail
                                            width={320}
                                            height={180}
                                        />
                                    </div>

                                    <div className='ms-2'>
                                        <RiImageAddFill size={36} color='blue' cursor="pointer" onClick={this.handleShowImageSelectModal} />
                                        <br />
                                        {this.state.croppedImage &&
                                            <RiDeleteBin5Line size={36} color='blue' cursor="pointer" onClick={() => this.handleShowDeleteModal()} />
                                        }
                                    </div>

                                </div>

                                <Container>

                                    <ImageSelectModal
                                        show={this.state.showImageSelectModal} // Modalın açık/kapalı durumunu geçirin
                                        handleClose={this.handleCloseImageSelectModal} // Modalı kapatan işlevi geçirin
                                        handleCallback={(croppedImage, croppedImageThumb, imageName) => {
                                            console.log(imageName);
                                            this.setState({
                                                featuredImage: imageName,
                                                croppedImage: croppedImage,
                                                croppedImageThumb: croppedImageThumb
                                            });
                                        }}
                                    />

                                    <ImageDeleteModal
                                        show={this.state.showDeleteModal} // Modalın açık/kapalı durumunu geçirin
                                        handleClose={this.handleCloseDeleteModal} // Modalı kapatan işlevi geçirin
                                        imageSrc={this.state.croppedImage ? URL.createObjectURL(this.state.croppedImage) : "/imgs/news-featured.jpg"}
                                        storageRef={this.state.storageRef}
                                        onImageDeleted={this.handleOnImageDeleted}
                                    />

                                </Container>
                            </Form.Group>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Title</strong></Form.Label>
                                <Form.Control
                                    id="title"
                                    type="text"
                                    placeholder="Enter title of the news"
                                    maxLength='200'
                                    value={title}
                                    onChange={this.handleChange}
                                    required
                                />
                                <Form.Label>url slug: {slug}</Form.Label>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Content</strong></Form.Label>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CKEditorComponent
                                        data={content}
                                        onChange={this.handleEditorChange}
                                        required
                                    />
                                </Suspense>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Related to News</strong></Form.Label>
                                <Form.Select
                                    id="projectId"
                                    aria-label="Select project"
                                    value={projectId}
                                    onChange={this.handleChange} // Handle select change
                                >
                                    <option>Please select from the list</option>
                                    {projects && projects.map((project) => (
                                        <option key={project.id} value={project.id}>
                                            {project.id} - {project.title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Form.Label><strong>Images</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">
                                    <div style={{
                                        position: 'flex', width: '160px', height: '90px', display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid blue'
                                    }} >
                                        {isSubImagesProgress ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                <RiImageAddFill size={48} color='blue' cursor="pointer" onClick={this.handleSubImagesIcon} />
                                                <input
                                                    type="file"
                                                    id="featuredImageInput"
                                                    accept="image/*"
                                                    onChange={this.onSubImagesFilesChange}
                                                    ref={this.fileInputRef}
                                                    style={{ display: 'none' }}
                                                    multiple
                                                />
                                            </>
                                        )}
                                    </div>
                                    {
                                        newsImages.map((newsImage, index) => (
                                            <div key={index}>
                                                <StorageImageComponent

                                                    imageName={newsImage}
                                                    path={"news"}

                                                />
                                            </div>
                                        ))
                                    }
                                    {
                                        newsImagesCache.map((imageFromCache, index) => (
                                            <div key={index}>
                                                <CacheImageComponent

                                                    image={imageFromCache}
                                                    index={index}
                                                    handleDelete={this.handleDeleteNewsImagesCacheByIndex}

                                                />
                                            </div>
                                        ))
                                    }
                                </div>

                            </FormGroup>
                            <br />

                            {errorMessage && <p className="text-danger">{errorMessage}</p>}

                            {this.state.isSubmitting ?
                                (
                                    <CircularProgress />

                                ) :
                                (
                                    <Row className='align-center'>
                                        <Col>
                                            <Button variant="primary" type="submit">
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>


                                )
                            }

                            <br />
                        </div>

                    </Form>
                </Container>

                <BottomSide />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        projects: state.firestore.ordered.projects,

    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        createNewsItem: (newsItem) => dispatch(createNews(newsItem))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'projects' },
    ])
)(AddNewsPage);


