// MyModal.js
import React, { useState } from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import { deleteObject } from 'firebase/storage';
import CircularProgress from '../../../components/common/CircularProgress';

const ImageDeleteModal = ({ show, handleClose, imageSrc, storageRef, onImageDeleted }) => {

    const [isProgressing, setIsProgressing] = useState(false);


    const handleDelete = async (e) => {
        e.preventDefault();

        setIsProgressing(true); // Show progress indicator
    
        try {
            await deleteObject(storageRef);

            onImageDeleted();
            handleClose();
        } catch (error) {
            console.error('Error deleting image:', error);
            // Handle error if deletion fails
        } finally {
            setIsProgressing(false); // Hide progress indicator
        }
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Confirm?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Do you confirm delete this image?</span>
                <Image
                    src={imageSrc}
                    thumbnail
                />
            </Modal.Body>
            <Modal.Footer>


                {isProgressing ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Button variant="secondary" className='me-2' onClick={handleClose}>
                            Close
                        </Button>

                        <Button variant="primary" className='me-2' onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ImageDeleteModal;