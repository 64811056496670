import React, { Component, createRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Breadcrumb, Button, Col, Container, Form, FormGroup, Row, Image } from 'react-bootstrap';
import BottomSide from '../../components/BottomSide';
import { updateNews } from '../../../store/actions/newsActions';
import { generateSlug } from '../../../utils/generateSlug';
import { RiImageAddFill } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import { storage } from '../../../config/fbConfig';
import '../AddNews/cropperStyles.css';
import ImageSelectModal from '../AddNews/ImageSelectModal';
import ImageDeleteModal from '../AddNews/ImageDeleteModal';
import { ref, uploadBytes, deleteObject } from 'firebase/storage';
import CircularProgress from '../../../components/common/CircularProgress';

import { getResizedImg, generateImageName, readFile } from '../../../utils/canvasUtils';
import StorageImageComponent from '../AddNews/StorageImageComponent';
import CacheImageComponent from '../AddNews/CacheImageComponent';
import NewsStorageImageComponent from '../AddNews/NewsStorageImageComponent';
import { Helmet } from 'react-helmet';

const CKEditorComponent = lazy(() => import('../../../components/common/CKEditorComponent'));

class UpdateNewsPage extends Component {

    constructor(props) {
        super(props);
        this.fileInputRef = createRef();
    }

    state = {
        title: '',
        projectId: '',
        slug: '',
        content: '',
        featuredImage: '',
        newsImages: [],
        newsImagesCache: [],
        errorMessage: null,
        croppedImage: null,
        croppedImageThumb: null,
        showImageSelectModal: false,
        showDeteleModal: false,
        isSubImagesProgress: false,
        isSubmitting: false,
        isLoading: true,
        newFeaturedImageName: '',
        willDeleteNewsImage: [],
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === "title")
            this.setState({
                slug: generateSlug(e.target.value)
            });
    }

    handleEditorChange = (data) => {
        this.setState({ content: data });
    }

    handleSubmit = async (e) => {

        const { croppedImage, croppedImageThumb, newsImagesCache, willDeleteNewsImage } = this.state;
        const { newsId } = this.props.match.params;

        if (this.state.content === '') {
            this.setState({ errorMessage: "Content required" });
            e.preventDefault();
        }
        else {
            this.setState({ isSubmitting: true });



            e.preventDefault();
            try {
                const promises = [];

                if (croppedImage) {
                    console.log("Başlık fotoğrafı değiştirilmiş..........");
                    console.log(this.state);
                    const newFeaturedImageName = generateImageName(croppedImage.type);
                    this.setState({ newFeaturedImageName: newFeaturedImageName });

                    //featured image uploading
                    const storageRef = ref(storage, "news/" + newFeaturedImageName);
                    promises.push(uploadBytes(storageRef, croppedImage));
                    //featured image thumb uploading
                    const storageRefThumb = ref(storage, "news/thumb_" + newFeaturedImageName);
                    promises.push(uploadBytes(storageRefThumb, croppedImageThumb));

                    /////Delete old one//////////////////////
                    const storageRef_ = ref(storage, "news/" + this.state.featuredImage);
                    promises.push(deleteObject(storageRef_));
                    //featured image thumb 
                    const storageRefThumb_ = ref(storage, "news/thumb_" + this.state.featuredImage);
                    promises.push(deleteObject(storageRefThumb_));
                    ////////////
                }

                for (let i = 0; i < newsImagesCache.length; i++) {
                    const imageItem = newsImagesCache[i];

                    const imageRef = ref(storage, "news/" + imageItem.name);
                    promises.push(uploadBytes(imageRef, imageItem.image));

                    const thumbRef = ref(storage, "news/thumb_" + imageItem.name);
                    promises.push(uploadBytes(thumbRef, imageItem.thumb));
                }

                //varsa silinen eski resimleri sil
                for (let i = 0; i < willDeleteNewsImage.length; i++) {
                    const imageItem = willDeleteNewsImage[i];

                    const imageRef = ref(storage, "news/" + imageItem);
                    promises.push(deleteObject(imageRef));

                    const thumbRef = ref(storage, "news/thumb_" + imageItem);
                    promises.push(deleteObject(thumbRef));
                }

                await Promise.all(promises);

                console.log("newsImages", this.state.willDeleteNewsImage);
                console.log("newsImageCache", newsImagesCache)
                const exactNewsImages = this.state.newsImages.concat(this.state.newsImagesCache.map(image => image.name));
                console.log("exactNewsImages", exactNewsImages);

                const newsItem = {
                    title: this.state.title,
                    projectId: this.state.projectId,
                    slug: this.state.slug,
                    content: this.state.content,
                    featuredImage: this.state.newFeaturedImageName ? this.state.newFeaturedImageName : this.state.featuredImage,
                    newsImages: exactNewsImages,
                    id: newsId
                }

                this.props.updateNewsItem(newsItem);
                this.props.history.push('/manage_news')

            } catch (error) {
                console.error(error)
            } finally {
                this.setState({ isSubmitting: false });
            }

        }
    }
    handleSubImagesIcon = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    }

    handleShowImageSelectModal = () => { this.setState({ showImageSelectModal: true }) }
    handleCloseImageSelectModal = () => { this.setState({ showImageSelectModal: false }) }

    handleShowDeleteModal = () => { this.setState({ showDeleteModal: true }) }
    handleCloseDeleteModal = () => { this.setState({ showDeleteModal: false }) }
    handleOnImageDeleted = () => { this.setState({ croppedImage: null }) }

    onSubImagesFilesChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {

            const files = e.target.files;

            const filePromises = [];

            this.setState({ isSubImagesProgress: true });

            try {
                for (let i = 0; i < files.length; i++) {
                    filePromises.push(readFile(files[i]));
                }

                const fileResults = await Promise.all(filePromises);

                const resizednewsImage = [];

                for (let i = 0; i < fileResults.length; i++) {
                    const resizedImage = await getResizedImg(fileResults[i], 1024, 576);
                    const resizedImageThumb = await getResizedImg(fileResults[i], 320, 180);

                    const imageName = generateImageName(resizedImage.type)
                    resizednewsImage.push({ image: resizedImage, thumb: resizedImageThumb, name: imageName });
                }

                this.setState((prevState) => ({
                    newsImagesCache: [...prevState.newsImagesCache, ...resizednewsImage],
                }));

            } catch (error) {
                console.error('Error reading files:', error);
            } finally {
                this.setState({ isSubImagesProgress: false });
            }
        }
    }

    handleDeleteNewsImagesCacheByIndex = ({ index }) => {
        const updatedNewsImagesCache = [...this.state.newsImagesCache];

        updatedNewsImagesCache.splice(index, 1);

        this.setState({
            newsImagesCache: updatedNewsImagesCache
        });
    }

    handleDeleteNewsImageStorageByImageName = (imageName) => {
        console.log("aaa", imageName);
        console.log("willDelete", this.state.willDeleteNewsImage);
        console.log("newsImages", this.state.newsImages);

        this.setState((prevState) => ({
            willDeleteNewsImage: [...prevState.willDeleteNewsImage, imageName],
            newsImages: prevState.newsImages.filter(
                (existingImageName) => existingImageName !== imageName
            )
        }), () => {
            // This callback is called after the state has been updated
            console.log("willDelete after update", this.state.willDeleteNewsImage);
            console.log("newsImages after update", this.state.newsImages);
        });
    }

    componentDidUpdate(prevProps) {
        // Check if the newsItem prop has changed
        if (this.props.newsItem !== prevProps.newsItem) {
            const { title, projectId, slug, content, featuredImage, newsImages } = this.props.newsItem || {};

            // Update the state with the new data
            this.setState({
                title: title || '',
                projectId: projectId || '',
                slug: slug || '',
                content: content || '',
                featuredImage: featuredImage || '',
                newsImages: newsImages || [],
                isLoading: false
            });
        }
    }

    render() {

        const { auth, signOut, projects } = this.props;
        const { title, projectId, slug, content, errorMessage, newsImages, newsImagesCache, isSubImagesProgress, isLoading } = this.state;


        if (!auth.uid)
            return <Redirect to="/login" replace />;

        return (
            <div>
                <Helmet>
                    <title>Update News - Bright Youth Community</title>
                </Helmet>

                <Navbar bg="dark" variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand href="#">Admin Panel</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="/admin  ">Dashboard</Nav.Link>
                                <Nav.Link href="/manage_news">News</Nav.Link>
                                <Nav.Link href="/manage_projetcs">Projects</Nav.Link>
                                <Nav.Link href="#" onClick={signOut}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Admin Panel</Breadcrumb.Item>
                        <Breadcrumb.Item href="/manage_news">Manage News</Breadcrumb.Item>
                        <Breadcrumb.Item active>Update News</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container>
                    <h1>Update News</h1>
                    <Form onSubmit={this.handleSubmit} className='bg-light'>

                        <div className='m-5'>

                            <br />
                            <Form.Group>

                                <Form.Label><strong>Featured Image</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">
                                    <div>

                                        {

                                            this.state.croppedImage ?
                                                (
                                                    <Image
                                                        id="featuredImg"
                                                        src={this.state.croppedImage ? URL.createObjectURL(this.state.croppedImage) : "/imgs/news-featured.jpg"}
                                                        thumbnail
                                                        width={320}
                                                        height={180}
                                                    />
                                                ) :
                                                (
                                                    <NewsStorageImageComponent
                                                        imageName={this.state.featuredImage}
                                                        path={"news"}
                                                    />

                                                )
                                        }

                                    </div>

                                    {isLoading ?
                                        (
                                            <CircularProgress />
                                        ) : (
                                            <div className='ms-2'>
                                                <RiImageAddFill size={36} color='blue' cursor="pointer" onClick={this.handleShowImageSelectModal} />
                                                <br />
                                                {this.state.croppedImage &&
                                                    <RiDeleteBin5Line size={36} color='blue' cursor="pointer" onClick={() => this.handleShowDeleteModal()} />
                                                }
                                            </div>

                                        )
                                    }


                                </div>

                                <Container>

                                    <ImageSelectModal
                                        show={this.state.showImageSelectModal} // Modalın açık/kapalı durumunu geçirin
                                        handleClose={this.handleCloseImageSelectModal} // Modalı kapatan işlevi geçirin
                                        handleCallback={(croppedImage, croppedImageThumb, imageName) => {
                                            this.setState({
                                                newFeaturedImage: imageName,
                                                croppedImage: croppedImage,
                                                croppedImageThumb: croppedImageThumb
                                            });
                                        }}
                                    />

                                    <ImageDeleteModal
                                        show={this.state.showDeleteModal} // Modalın açık/kapalı durumunu geçirin
                                        handleClose={this.handleCloseDeleteModal} // Modalı kapatan işlevi geçirin
                                        imageSrc={this.state.croppedImage ? URL.createObjectURL(this.state.croppedImage) : "/imgs/news-featured.jpg"}
                                        storageRef={this.state.storageRef}
                                        onImageDeleted={this.handleOnImageDeleted}
                                    />

                                </Container>
                            </Form.Group>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Title</strong></Form.Label>
                                <Form.Control
                                    id="title"
                                    type="text"
                                    placeholder="Enter title of the news"
                                    maxLength='200'
                                    value={title}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                                <Form.Label>url slug: {slug}</Form.Label>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Content</strong></Form.Label>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CKEditorComponent
                                        data={content}
                                        onChange={this.handleEditorChange}
                                        required
                                        disabled={isLoading}
                                    />
                                </Suspense>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Related to News</strong></Form.Label>
                                <Form.Select
                                    id="projectId"
                                    aria-label="Select project"
                                    value={projectId}
                                    onChange={this.handleChange} // Handle select change
                                    disabled={isLoading}
                                >
                                    <option>Please select from the list</option>
                                    {projects && projects.map((project) => (
                                        <option key={project.id} value={project.id}>
                                            {project.id} - {project.title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Form.Label><strong>Images</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">
                                    <div style={{
                                        position: 'flex', width: '160px', height: '90px', display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid blue'
                                    }} >
                                        {isSubImagesProgress || isLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                <RiImageAddFill size={48} color='blue' cursor="pointer" onClick={this.handleSubImagesIcon} />
                                                <input
                                                    type="file"
                                                    id="featuredImageInput"
                                                    accept="image/*"
                                                    onChange={this.onSubImagesFilesChange}
                                                    ref={this.fileInputRef}
                                                    style={{ display: 'none' }}
                                                    multiple
                                                />
                                            </>
                                        )}
                                    </div>

                                    {

                                        newsImages.map((newsImage, index) => (
                                            <div key={index}>
                                                <StorageImageComponent
                                                    imageName={newsImage}
                                                    path={"news"}
                                                    handleDelete={this.handleDeleteNewsImageStorageByImageName}

                                                />
                                            </div>
                                        ))
                                    }
                                    {
                                        newsImagesCache.map((imageFromCache, index) => (
                                            <div key={index}>
                                                <CacheImageComponent

                                                    image={imageFromCache}
                                                    index={index}
                                                    handleDelete={this.handleDeleteNewsImagesCacheByIndex}

                                                />
                                            </div>
                                        ))
                                    }
                                </div>

                            </FormGroup>
                            <br />

                            {errorMessage && <p className="text-danger">{errorMessage}</p>}

                            {this.state.isSubmitting ?
                                (
                                    <CircularProgress />

                                ) :
                                (
                                    <Row className='align-center'>
                                        <Col>
                                            <Button variant="primary" type="submit">
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }

                            <br />
                        </div>

                    </Form>
                </Container>

                <BottomSide />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        projects: state.firestore.ordered.projects,
        newsItem: state.firestore.data.newsItem
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        updateNewsItem: (newsItem) => dispatch(updateNews(newsItem))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        const { newsId } = props.match.params;
        return [
            { collection: 'projects' },
            { collection: 'news', storeAs: 'newsItem', doc: newsId }
        ]
    })
)(UpdateNewsPage);
