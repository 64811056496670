import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import { signOut } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';

class AdminPanelMenuBar extends Component {

  handleSignOut = () => {
    this.props.signOut();
  }

  render() {
    const { auth } = this.props;

    if (!auth.uid)
      return <Redirect to="/login" replace />;

    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#">Admin Panel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/admin  ">Dashboard</Nav.Link>
              <Nav.Link href="/manage_news">News</Nav.Link>
              <Nav.Link href="/manage_projects">Projects</Nav.Link>
              <Nav.Link href="#" onClick={this.handleSignOut}>
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AdminPanelMenuBar);