export const signIn = (credentials) => {
    
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase()
        
        firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(()=>{
            dispatch({ type: 'LOGIN_SUCCESSFULL' });
        }).catch((err)=>{
            dispatch({ type: 'LOGIN_ERROR', err });

        })
    }
}

export const signInWithGoogle = () => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase()
        const provider = new firebase.auth.GoogleAuthProvider();
       

        firebase.auth() && getFirebase().auth().signInWithPopup(provider)
            .then((signInDoc) => {
                dispatch({ type: 'LOGIN_SUCCESSFULL' });
            })

            .catch(err => {
                dispatch({ type: 'LOGIN_ERROR', err });
            });
    }
}


export const signOut = () => {
    
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase()
        
        firebase.auth().signOut()
        .then(()=>{
            dispatch({ type: 'SIGNOUT_SUCCESS' });
        }).catch((err)=>{
            dispatch({ type: 'SIGNOUT_ERROR', err });

        })
    }
}