
import React from 'react';
import { Nav } from 'react-bootstrap';

import { Image,} from 'react-bootstrap';
import { FaFacebook, FaInstagram } from "react-icons/fa6";

const BottomSide = () => {
    return (
        <div className='bg-secondary'>
            
            <div className='text-center'>
                <Image
                    className='mt-5 mb-5'
                    src="/imgs/byc-logo-trans.png"
                    alt="BYC Logo"
                    fluid
                    style={{
                        height: '54px', // İstediğiniz yükseklik
                    }}

                />
            </div>

            <div>
                <Nav className="justify-content-center">
                    <Nav.Link href="/" style={{ color: 'white' }}>Home</Nav.Link>
                    <Nav.Link href="/news" style={{ color: 'white' }}>News</Nav.Link>
                    <Nav.Link href="/projects" style={{ color: 'white' }}>Projects</Nav.Link>
                    <Nav.Link href="/about-us" style={{ color: 'white' }}>About s</Nav.Link>
                    <Nav.Link href="/contact" style={{ color: 'white' }}>Contact</Nav.Link>
                </Nav>

            </div>

            <div className='text-center'>
                <a href="https://www.facebook.com/profile.php?id=61550836803822" target='_blank' rel="noreferrer"><FaFacebook size={48} color='lightgray' className=' mb-5 me-2 mt-5'/></a>
                <a href="https://www.instagram.com/bright_youth_community" target='_blank' rel="noreferrer"><FaInstagram  size={48} color= "lightgray" className=' mb-5 mt-5'/></a>
            </div>

            <div className='text-center'>
                <small style={{ color: 'lightgray' }}>
                © 2024 Bright Youth Community. All Rights Reserved.<br />
                webApp ver. 1.0.53
                </small>
            </div>

            <div style={{ height: '100px' }} />
        </div>

    )
};

export default BottomSide;