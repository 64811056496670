import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import ProjectPartnerListComponent from './ProjectPartnerListComponent';
import ProjectInfoComponent from './ProjectInfoComponent';
import ProjectNewsComponent from './ProjectNewsComponent';


const ProjectDetailsComponent = ({ project }) => {

    if (!project)
        return (
            <CenteredCircularProgress />
        );

    return (
        <Container className='mt-4'>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col>
                            <ProjectInfoComponent
                                project={project}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ProjectNewsComponent 
                                projectId = {project?.id}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col md={4} className='mb-5'>
                    <ProjectPartnerListComponent
                        partners={project?.partners}
                    />
                </Col>

            </Row>

        </Container>
    );

}



export default ProjectDetailsComponent;


