// MyModal.js
import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import './cropperStyles.css';
import { getCroppedImg, generateImageName } from "../../../utils/canvasUtils";
import CircularProgress from '../../../components/common/CircularProgress';

const ImageSelectModal = ({ show, handleClose, handleCallback }) => {

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const fileInputRef = useRef(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {

      const file = e.target.files[0];

      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        //const orientation = await getOrientation(file)

      } catch (e) {
        console.warn('failed to detect the orientation')
      }

      setImageSrc(imageDataUrl)
    }
  }

  const uploadCroppedImage = async () => {
    try {
      setIsUpdating(true);
      
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        0,
        { horizontal: false, vertical: false },
        1024,
        576
      );
 
      const croppedImageThumb = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        0,
        { horizontal: false, vertical: false },
        320,
        180
      );
 

      //setCroppedImage(croppedImage);
      //resim yükleniyor
      var newName = generateImageName(croppedImage.type);
      
      

      //await uploadBytes(storageRef, croppedImage);
      console.log('File uploaded successfully');
      setImageSrc(null);
      handleCallback(croppedImage, croppedImageThumb, newName);
      handleClose();
      setIsUpdating(false);

    } catch (e) {
      setIsUpdating(false);
      console.error(e)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Select Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Use mouse to resize and zoom the image (Ratio: 16/9)</span>
        <div className="crop-container0">
          <React.Fragment>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              objectFit="contain"
            />
          </React.Fragment>
        </div>
        <Form.Group controlId="formZoomSlider" className="mt-3">
          <Form.Range
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e) => setZoom(e.target.value)}
          />
        </Form.Group>


      </Modal.Body>
      <Modal.Footer>

        <input
          type="file"
          id="featuredImageInput"
          accept="image/*"
          onChange={onFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />

        {isUpdating ? (
          <CircularProgress />
        ) : (
          <div>
            <Button variant="secondary" onClick={handleClose} className='me-2'>
              Close
            </Button>

            <Button variant="secondary" className='me-2' onClick={(event) => {
              event.preventDefault();
              fileInputRef.current.click();
            }}>
              Select Image
            </Button>

            <Button variant="primary" className='me-2' onClick={uploadCroppedImage}>
              Upload Image
            </Button>
          </div>
        )}


      </Modal.Footer>
    </Modal>
  );
};

export default ImageSelectModal;


function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

