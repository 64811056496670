import React from 'react';
import { Carousel, Image, Container, Breadcrumb, Row, Col } from 'react-bootstrap';

import { BsTelephoneInbound } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { MdAttachEmail } from "react-icons/md";
import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';

function ContactPage() {
    const currentUrl = window.location.href;

    return (
        <div className='mb-8'>
            <Helmet>
                <title>Contact - Bright Youth Community</title>
                <link rel="canonical" href={currentUrl} />
                <meta property="og:title" content="Contact - Bright Youth Community" />
                <meta property="og:description" content="Bright Youth Community Contact Page" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact - Bright Youth Community" />
                <meta name="twitter:description" content="Bright Youth Community Contact Page" />
                <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
            </Helmet>

            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <Image
                        className="d-block w-100"
                        src="imgs/bg-breadcrumbs-contact.jpg"
                        alt="Contact"
                        fluid
                        style={{
                            height: '360px', // İstediğiniz yükseklik
                            objectFit: 'cover', // Resmi kırparak kapsayıcıya sığdırır
                            objectPosition: 'center', // Resmi ortalar
                            opacity: 0.7
                        }}
                    />
                    <Carousel.Caption>
                        <h1>Contact</h1>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

            <Container className="mt-4">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact</Breadcrumb.Item>
                </Breadcrumb>
            </Container>



            <Container className='mb-4'>
                <Row className='mb-4 mt-4'>
                    <Col className="text-center mt-4 mb-4">
                        <b className="display-5">Contact</b>
                    </Col>

                </Row>

                <Container fluid>
                    <Row className="no-gutters">
                        <Col md={6} lg={4} className='text-center'>
                            <BsTelephoneInbound size={96} className="me-2" color='green' opacity={0.5} />
                            <h3 className='mt-2'>
                                +90 505 386 87 66
                            </h3>
                            <p>
                                You can reach our association between 10:00 AM and 6:00 PM (Turkish time) on weekdays
                            </p>
                        </Col>
                        <Col md={6} lg={4} className='text-center'>
                            <FaLocationDot size={96} className="me-2" color='green' opacity={0.5} />
                            <h5 className='mt-2'>
                                Kalınağıl Mah. Merkez 54 Sok. No: 201 48200 Milas, Muğla TÜRKİYE
                            </h5>

                        </Col>
                        <Col md={6} lg={4} className='text-center'>
                            <MdAttachEmail size={96} className="me-2" color='green' opacity={0.5} />
                            <h5 className='mt-2'>
                                brightyouthcommunity@gmail.com
                            </h5>
                            <p>
                                Feel free to email us your questions
                            </p>
                        </Col>

                    </Row>
                </Container>

            </Container>

            <div className='bg-light'>
                <Container>
                    <Row>
                        <Col sm={4} ></Col>
                        <Col sm={4} className='text-center'>
                            {/* Removed unnecessary style attribute */}
                            <Image
                                className="d-block w-100"
                                src="imgs/milas-map.jpg"
                                alt="Milas Map"
                                thumbnail
                            />
                        </Col>
                        <Col sm={4} ></Col>
                    </Row>
                </Container>
            </div>

            <div>

                <BottomSide />
            </div>


        </div>



    );
}

export default ContactPage;