import React from 'react';
import { Image, Container, Breadcrumb, Row, Col } from 'react-bootstrap';

import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    const currentUrl = window.location.href;

    return (
        <div className='mb-8'>
            <Helmet>
                <title>Contact - Bright Youth Community</title>
                <link rel="canonical" href={currentUrl} />
                <meta property="og:title" content="Page Not Found - Bright Youth Community" />
                <meta property="og:description" content="Page Not Found" />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Page Not Found - Bright Youth Community" />
                <meta name="twitter:description" content="Page Not Found" />
                <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
            </Helmet>

            <Container className="mt-4">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Page Not Found</Breadcrumb.Item>
                </Breadcrumb>
            </Container>



            <Container className='mb-4'>
                <Row className='mb-4 mt-4'>
                    <Col className="text-center mt-4 mb-4">
                        <b className="display-5">Page Not Found</b>
                    </Col>

                </Row>

                <div style={{ textAlign: 'center', padding: '50px' }}>
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <Link to="/">Go to Homepage</Link>
                </div>

            </Container>

            <div>

                <BottomSide />
            </div>


        </div>

    );
}

export default NotFoundPage;