import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { Redirect, Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Breadcrumb, Col, Container, Row, Table } from 'react-bootstrap';
import BottomSide from '../components/BottomSide';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { RiAddBoxLine } from "react-icons/ri";
import DeleteNewsModal from './DeleteNewsModal';
import { deleteNewsItem } from '../../store/actions/newsActions';
import AdminPanelMenuBar from '../Admin/AdminPanelMenuBar';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import { Helmet } from 'react-helmet';
//import { newsStatus } from '../../Consts';

class NewsManagementPage extends Component {

    state = {
        currentPage: 1,
        itemsPerPage: 20,
        willDelete: null,
        showDeleteModal: false,

    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    componentDidMount() {
        const { match } = this.props;
        const { pageNumber } = match.params;
        if (pageNumber) {
            this.setState({ currentPage: parseInt(pageNumber, 20) });
        }
    }

    handleDeleteCallback = (newsItem) => {
        //const storage = 
        this.setState({
            willDelete: newsItem,
            showDeleteModal: true,
            //willDeleteStorage:
        })

        // Implement the deletion logic here
    }

    handleCloseDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        })
    }

    render() {


        const { auth, news } = this.props;
        const { currentPage, itemsPerPage } = this.state;

        if (!auth.uid)
            return <Redirect to="/login" replace />;

        // Check if news is undefined
        if (!news) {
            return <CenteredCircularProgress />
        }

        // Calculate pagination values
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentNews = news.slice(indexOfFirstItem, indexOfLastItem);

        // Render pagination items
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(news.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (

            <div>
                <Helmet>
                    <title>Manage News - Bright Youth Community</title>
                </Helmet>

                <AdminPanelMenuBar />

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Admin Panel</Breadcrumb.Item>
                        <Breadcrumb.Item active>Manage News</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <ManageNewsListComponent
                    news={currentNews}
                    handleDeleteCallback={this.handleDeleteCallback}
                />

                {/* Pagination controls */}
                <Container className="mt-5 mb-5">
                    <Row>
                        <Col xs={8}>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {/* Previous button */}
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <Link to={`/news/page/${currentPage - 1}`} className="page-link" onClick={() => this.handlePageChange(currentPage - 1)}>Previous</Link>
                                    </li>

                                    {/* Page numbers */}
                                    {pageNumbers.map((number) => (
                                        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                            <Link to={`/news/page/${number}`} className="page-link" onClick={() => this.handlePageChange(number)}>
                                                {number}
                                                {number === currentPage && <span className="sr-only"></span>}
                                            </Link>
                                        </li>
                                    ))}

                                    {/* Next button */}
                                    <li className={`page-item ${currentPage === Math.ceil(news.length / itemsPerPage) ? 'disabled' : ''}`}>
                                        <Link to={`/news/page/${currentPage + 1}`} className="page-link" onClick={() => this.handlePageChange(currentPage + 1)}>Next</Link>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                        <Col xs={4} className="text-end">
                            <p>Page {currentPage} of {Math.ceil(news.length / itemsPerPage)}</p>
                        </Col>
                    </Row>
                </Container>

                <BottomSide />

                <DeleteNewsModal
                    show={this.state.showDeleteModal}
                    handleClose={this.handleCloseDeleteModal}
                    message={"News Title: " + this.state.willDelete?.title}
                    newsItem={this.state.willDelete}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        news: state.firestore.ordered.news,
        newsCount: state.firestore.data.metadata ? state.firestore.data.metadata.newsCount?.count : undefined,

    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        deleteNewsItem: (newsItem) => dispatch(deleteNewsItem(newsItem))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'news', orderBy: ['created', 'desc'] },
        { collection: 'metadata', doc: 'newsCount' },

    ])
)(NewsManagementPage);



const ManageNewsListComponent = ({ news, handleDeleteCallback }) => {

    return (
        <>
            <Container className='mt-1 mb-5'>
                <Row>
                    <Col sm={12}>
                        <h2 className="mt-4 mb-4">Manage News</h2>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} className='d-flex justify-content-end mb-2'>
                        <RiAddBoxLine size={24} color='blue' className='me-1' />
                        <Link to="/add_news" className="text-end">Add News</Link>
                    </Col>
                </Row>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Line</th>
                            <th>Status</th>
                            <th>Title</th>
                            <th>Created</th>
                            <th>User</th>
                            <th>ProjectId</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {news && news.map((newsItem, index) => (
                            <tr key={newsItem.id}>
                                <td>{index + 1}</td>
                                <td>{"Active"}</td>
                                <td>{
                                    <a href={`/news/${newsItem.slug}`} target="_blank" rel="noopener noreferrer">
                                        {newsItem.title}
                                    </a>}
                                </td>
                                <td>{newsItem.created ? new Date(newsItem.created.seconds * 1000).toLocaleString() : ''}</td>
                                <td>{"User"}</td>
                                <td>{newsItem.projectId}</td>
                                <td className='align-end'>
                                    <Link to={`/update_news/${newsItem.id}`}>
                                        <FaEdit size={18} className="me-2" color='blue' />
                                    </Link>

                                    <RiDeleteBin5Fill
                                        size={18}
                                        className="ms-2"
                                        color='blue'
                                        cursor="pointer"
                                        onClick={() => handleDeleteCallback(newsItem)}
                                    />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </Container>

        </>

    );
}