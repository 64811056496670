const initState = {
    authError: null
}

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case 'LOGIN_SUCCESSFULL':
            console.log('login successful')
            return {
                ...state,
                authError: null
            };
        case 'LOGIN_ERROR':
            console.log('login failed')
            return {
                ...state,
                authError: 'Login Failed'
            };
        case 'SIGNOUT_SUCCESS':
            console.log('signout successfull')
            return {
                ...state
            }
        default:
            return state;
    }
}


export default authReducer