import { AiOutlineClose } from 'react-icons/ai';
import { Image } from 'react-bootstrap';

const CacheImageComponent = ({ image, index, handleDelete }) => {

    return (

        <div className="ms-2 mb-2" style={{ position: 'relative', width: '160px', height: '90px' }}>

            <>
                <Image
                    src={image.thumb ? URL.createObjectURL(image.thumb) : "/imgs/news-featured.jpg"}
                    alt=""
                    thumbnail
                    style={{ height: '100%' }}
                    onError={(e) => console.error('Error loading image', e)}
                />
                <AiOutlineClose
                    size={24}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                        color: 'blue',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: '5px'
                    }}
                    onClick={() => handleDelete(index)}
                />
            </>

        </div>

    );
}

export default CacheImageComponent;