exports.generateSlug = (title) => {
    const turkishMap = {
        'Ş': 's', 'ş': 's',
        'Ç': 'c', 'ç': 'c',
        'Ö': 'o', 'ö': 'o',
        'Ğ': 'g', 'ğ': 'g',
        'Ü': 'u', 'ü': 'u',
        'İ': 'i', 'ı': 'i'
    };

    return title
        .split('')
        .map(char => turkishMap[char] || char) // Replace Turkish characters
        .join('')
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
        .replace(/^-+|-+$/g, ''); // Remove leading or trailing hyphens
};