import React, { Component, createRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Breadcrumb, Button, Col, Container, Form, FormGroup, Row, Image } from 'react-bootstrap';
import BottomSide from '../../components/BottomSide';
import { RiImageAddFill } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import { storage } from '../../../config/fbConfig';
import '../AddProject/cropperStyles.css';
import { ref, uploadBytes, deleteObject } from 'firebase/storage';
import CircularProgress from '../../../components/common/CircularProgress';
import { generateImageName } from '../../../utils/canvasUtils';
import { updateProject } from '../../../store/actions/projectActions';
import AdminPanelMenuBar from '../../Admin/AdminPanelMenuBar';
import ProjectLogoSelectModal from '../AddProject/ProjectLogoSelectModal';
import { MdOutlineAddBox } from "react-icons/md";
import ProjectPartnerComponent from '../AddProject/ProjectPartnerComponent';
import PartnerSelectModal from '../AddProject/PartnerSelectModal';
import { firestoreConnect } from 'react-redux-firebase';
import ProjectLogoFromStorage from './ProjectLogoFromStorage';
import { Helmet } from 'react-helmet';
import { owners, projectStatuses } from '../../../Consts';

const CKEditorComponent = lazy(() => import('../../../components/common/CKEditorComponent'));

class UpdateProjectPage extends Component {

    constructor(props) {
        super(props);
        this.fileInputRef = createRef();
    }

    state = {
        accFacebook: '',
        accInstagram: '',
        accWebSite: '',
        budget: '',
        description: '',
        endDate: '',
        keyAction: '',
        owner: '',
        partners: [],
        projectLogo: '',
        projectType: '',
        referanceNumber: '',
        startDate: '',
        title: '',
        topics: '',
        projectStatus: '',
        projectId: '',

        willDeleteProjectLogo: '',
        newProjectLogo: '',

        errorMessage: null,
        croppedImage: null,
        croppedImageThumb: null,
        showImageSelectModal: false,
        showPartnerSelectModal: false,
        isSubmitting: false,

        isLoading: true
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleEditorChange = (data) => {
        this.setState({ description: data });
    }

    handleSubmit = async (e) => {

        const { croppedImage, croppedImageThumb, willDeleteProjectLogo } = this.state;
        const { projectId } = this.props.match.params;

        if (this.state.description === '') {
            this.setState({ errorMessage: "Project description required" });
            e.preventDefault();
        }
        else {
            e.preventDefault();

            this.setState({ isSubmitting: true });

            try {
                const promises = [];

                if (croppedImage) {
                    const logoFileName = generateImageName(croppedImage.type)
                    this.setState({ newProjectLogo: logoFileName });

                    //featured ipage uploading
                    const storageRef = ref(storage, "projects/" + logoFileName);
                    promises.push(uploadBytes(storageRef, croppedImage));
                    //featured image thumb uploading
                    const storageRefThumb = ref(storage, "projects/thumb_" + logoFileName);
                    promises.push(uploadBytes(storageRefThumb, croppedImageThumb));
                }

                //varsa silinen eski logoyu sil
                if (willDeleteProjectLogo) {
                    const imageRef = ref(storage, "projects/" + willDeleteProjectLogo);
                    promises.push(deleteObject(imageRef));

                    const thumbRef = ref(storage, "projects/thumb_" + willDeleteProjectLogo);
                    promises.push(deleteObject(thumbRef));
                }

                await Promise.all(promises);

                const projectItem = {
                    title: this.state.title,
                    description: this.state.description,
                    projectLogo: this.state.newProjectLogo ? this.state.newProjectLogo : this.state.projectLogo,
                    accFacebook: this.state.accFacebook,
                    accInstagram: this.state.accInstagram,
                    accWebSite: this.state.accWebSite,
                    budget: this.state.budget,
                    endDate: this.state.endDate,
                    keyAction: this.state.keyAction,
                    owner: this.state.owner,
                    partners: this.state.partners,
                    projectType: this.state.projectType,
                    referanceNumber: this.state.referanceNumber,
                    startDate: this.state.startDate,
                    topics: this.state.topics,
                    projectStatus: this.state.projectStatus,
                }

                this.props.updateProject(projectItem, projectId);
                this.props.history.push('/manage_projects')

            } catch (error) {
                console.error(error)
            } finally {
                this.setState({ isSubmitting: false });
            }

        }
    }

    handleShowImageSelectModal = () => { this.setState({ showImageSelectModal: true }) }
    handleCloseImageSelectModal = () => { this.setState({ showImageSelectModal: false }) }

    handleDeleteProjectImage = () => {
        if (this.state.croppedImage) {
            this.setState({
                croppedImage: null,
                croppedImageThumb: null,
                newProjectLogo: ""
            });
        } else {
            this.setState({
                willDeleteProjectLogo: this.state.projectLogo,
                projectLogo: '',
            },
                () => {
                    // This code will run after the state has been updated
                    console.log('Old project logo will update');
                }
            )
        }
    }

    handleShowPartnerSelectModal = () => { this.setState({ showPartnerSelectModal: true }) }
    handleClosePartnerSelectModal = () => { this.setState({ showPartnerSelectModal: false }) }

    handleAddPartner = (formValues) => {
        this.setState((prevState) => ({
            partners: [...prevState.partners, formValues]
        }));
    };

    handlePartnerDelete = (partnerToDelete) => {
        this.setState((prevState) => ({
            partners: prevState.partners.filter(partner => partner !== partnerToDelete)
        }));
    };

    componentDidUpdate(prevProps) {
        // Check if the newsItem prop has changed
        if (this.props.projectItem !== prevProps.projectItem) {
            const {
                title, description, projectLogo, accFacebook, accInstagram, accWebSite, budget, endDate,
                keyAction, owner, partners, projectType, referanceNumber, startDate, topics, projectStatus,
            } = this.props.projectItem || {};

            const { projectId } = this.props.match.params;

            // Update the state with the new data
            this.setState({
                isLoading: false,

                title: title || '',
                projectId: projectId || '',
                description: description || '',
                projectLogo: projectLogo || '',
                accFacebook: accFacebook || '',
                accInstagram: accInstagram || '',
                accWebSite: accWebSite || '',
                budget: budget || '',
                endDate: endDate || '',
                keyAction: keyAction || '',
                owner: owner || '',
                partners: partners || [],
                projectType: projectType || '',
                referanceNumber: referanceNumber || '',
                startDate: startDate || '',
                topics: topics || '',
                projectStatus: projectStatus || '',
            });
        }
    }

    render() {

        //const { auth, signOut, projects } = this.props;
        const { title, projectId, description, errorMessage, partners, isLoading,
            topics, referanceNumber, startDate, endDate, projectType,
            keyAction, budget, accFacebook, accInstagram, accWebSite, owner, projectStatus,
        } = this.state;


        return (
            <div>
                <Helmet>
                    <title>Update Project - Bright Youth Community</title>
                </Helmet>

                <AdminPanelMenuBar />

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Admin Panel</Breadcrumb.Item>
                        <Breadcrumb.Item href="/manage_projects">Manage Projects</Breadcrumb.Item>
                        <Breadcrumb.Item active>Update Project</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container>
                    <h1>Update Project</h1>
                    <Form onSubmit={this.handleSubmit} className='bg-light'>

                        <div className='m-5'>

                            <br />
                            <Form.Group>

                                <Form.Label><strong>Project Logo</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">
                                    <div>
                                        {
                                            this.state.projectLogo && !this.state.newProjectLogo ?
                                                (
                                                    <ProjectLogoFromStorage
                                                        imageName={this.state.projectLogo}
                                                        path={"projects"}
                                                    />
                                                ) :
                                                (
                                                    <Image
                                                        id="projectLogo"
                                                        src={this.state.croppedImage ? URL.createObjectURL(this.state.croppedImage) : "/imgs/project-logo-placeholder.png"}
                                                        thumbnail
                                                        width={180}
                                                        height={180}
                                                    />
                                                )
                                        }

                                    </div>

                                    {isLoading ?
                                        (
                                            <CircularProgress />
                                        ) : (
                                            <div className='ms-2'>
                                                <RiImageAddFill size={36} color='blue' cursor="pointer" onClick={this.handleShowImageSelectModal} />
                                                <br />
                                                {(this.state.croppedImage || this.state.projectLogo) &&
                                                    <RiDeleteBin5Line size={36} color='blue' cursor="pointer" onClick={() => this.handleDeleteProjectImage()} />
                                                }
                                            </div>
                                        )
                                    }

                                </div>

                                <Container>

                                    <ProjectLogoSelectModal
                                        show={this.state.showImageSelectModal} // Modalın açık/kapalı durumunu geçirin
                                        handleClose={this.handleCloseImageSelectModal} // Modalı kapatan işlevi geçirin
                                        handleCallback={(croppedImage, croppedImageThumb, imageName) => {

                                            this.setState(prevState => {

                                                const newState = {
                                                    newProjectLogo: imageName,
                                                    croppedImage: croppedImage,
                                                    croppedImageThumb: croppedImageThumb
                                                };

                                                if (prevState.projectLogo) {
                                                    newState.willDeleteProjectLogo = prevState.projectLogo;
                                                }

                                                return newState;
                                            });

                                        }}
                                    />

                                </Container>
                            </Form.Group>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project No *</strong></Form.Label>
                                <Form.Control
                                    id="projectId"
                                    type="text"
                                    placeholder="Enter project no (e.g. 004)"
                                    maxLength='3'
                                    value={projectId}
                                    onChange={this.handleChange}
                                    disabled
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Name *</strong></Form.Label>
                                <Form.Control
                                    id="title"
                                    type="text"
                                    placeholder="Enter project name"
                                    maxLength='300'
                                    value={title}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Referance Number *</strong></Form.Label>
                                <Form.Control
                                    id="referanceNumber"
                                    type="text"
                                    placeholder="Enter project legal referance number"
                                    maxLength='150'
                                    value={referanceNumber}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Start Date *</strong></Form.Label>
                                <Form.Control
                                    id="startDate"
                                    type="text"
                                    placeholder="Enter start date (dd.mm.yyyy)"
                                    maxLength='10'
                                    value={startDate}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project End Date *</strong></Form.Label>
                                <Form.Control
                                    id="endDate"
                                    type="text"
                                    placeholder="Enter end date (dd.mm.yyyy)"
                                    maxLength='10'
                                    value={endDate}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Type *</strong></Form.Label>
                                <Form.Control
                                    id="projectType"
                                    type="text"
                                    placeholder="Enter project type"
                                    maxLength='100'
                                    value={projectType}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Key Action</strong></Form.Label>
                                <Form.Control
                                    id="keyAction"
                                    type="text"
                                    placeholder="Enter key action"
                                    maxLength='100'
                                    value={keyAction}
                                    onChange={this.handleChange}
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Budget</strong></Form.Label>
                                <Form.Control
                                    id="budget"
                                    type="text"
                                    placeholder="Enter project budget"
                                    maxLength='12'
                                    value={budget}
                                    onChange={this.handleChange}
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Facebook Account</strong></Form.Label>
                                <Form.Control
                                    id="accFacebook"
                                    type="text"
                                    placeholder="Enter URL (https://)"
                                    value={accFacebook}
                                    onChange={this.handleChange}
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Instagram Account</strong></Form.Label>
                                <Form.Control
                                    id="accInstagram"
                                    type="text"
                                    placeholder="Enter URL (https://)"
                                    value={accInstagram}
                                    onChange={this.handleChange}
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Website</strong></Form.Label>
                                <Form.Control
                                    id="accWebSite"
                                    type="text"
                                    placeholder="Enter URL (https://)"
                                    value={accWebSite}
                                    onChange={this.handleChange}
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Description *</strong></Form.Label>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CKEditorComponent
                                        data={description}
                                        onChange={this.handleEditorChange}
                                        required
                                        disabled={isLoading}
                                    />
                                </Suspense>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Topics *</strong></Form.Label>
                                <Form.Control
                                    id="topics"
                                    type="text"
                                    placeholder="Enter project topics. Use comma after each topics"
                                    maxLength='300'
                                    value={topics}
                                    onChange={this.handleChange}
                                    required
                                    disabled={isLoading}
                                />
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Owner *</strong></Form.Label>
                                <Form.Select
                                    id="owner"
                                    aria-label="Select Owner"
                                    value={owner}
                                    onChange={this.handleChange} // Handle select change
                                    required
                                    disabled={isLoading}
                                >
                                    <option value="">Please select an item</option>
                                    {owners.map((owner, index) => (
                                        <option key={index} value={index}>{owner}</option>
                                    ))}
                                </Form.Select>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Status *</strong></Form.Label>
                                <Form.Select
                                    id="projectStatus"
                                    aria-label="Select Project Status"
                                    value={projectStatus}
                                    onChange={this.handleChange} // Handle select change
                                    required
                                    disabled={isLoading}
                                >
                                    <option value="">Please select an item</option>
                                    {projectStatuses.map((pStatus, index) => (
                                        <option key={index} value={index}>{pStatus}</option>
                                    ))}
                                </Form.Select>
                            </FormGroup>
                            <br />

                            <FormGroup>
                                <Form.Label><strong>Project Partners</strong></Form.Label>

                                <div className="d-flex flex-row flex-wrap">

                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <div style={{
                                            position: 'flex', width: '90px', height: '90px', display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '1px solid blue'
                                        }} >
                                            <MdOutlineAddBox size={48} color='blue' cursor="pointer" onClick={this.handleShowPartnerSelectModal} />
                                        </div>
                                    )
                                    }

                                    {
                                        partners.map((partner, index) => (
                                            <div key={index}>
                                                <ProjectPartnerComponent
                                                    partner={partner}
                                                    handleDelete={this.handlePartnerDelete}
                                                />
                                            </div>
                                        ))
                                    }



                                </div>

                            </FormGroup>
                            <br />

                            {errorMessage && <p className="text-danger">{errorMessage}</p>}

                            {this.state.isSubmitting ?
                                (
                                    <CircularProgress />

                                ) :
                                (
                                    <Row className='align-center'>
                                        <Col>
                                            <Button variant="primary" type="submit">
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>

                                )
                            }

                            <br />
                        </div>

                    </Form>
                </Container>
                <PartnerSelectModal
                    show={this.state.showPartnerSelectModal} // Modalın açık/kapalı durumunu geçirin
                    handleClose={this.handleClosePartnerSelectModal} // Modalı kapatan işlevi geçirin
                    handleCallback={this.handleAddPartner}
                />

                <BottomSide />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        projectItem: state.firestore.data.projectItem
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (pItem, projectId) => dispatch(updateProject(pItem, projectId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => {
        const { projectId } = props.match.params;
        return [
            { collection: 'projects', storeAs: 'projectItem', doc: projectId }
        ]
    })
)(UpdateProjectPage);