import React, { Component } from 'react'
import ProjectList from "./ProjectList";
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap';
import BottomSide from '../components/BottomSide';
import { Helmet } from 'react-helmet';
import CenteredCircularProgress from '../../components/common/CenteredCircularProgress';
import axios from 'axios';

class ProjectsPage extends Component {
    _isMounted = false;

    state = {
        items: [],
        loading: true,
        error: null,
        lastCreated: null,
        hasMore: true,
    };

    async fetchItemsData() {
        this.setState({ loading: true });

        const itemsLimit = 8;

        const { lastCreated } = this.state;
        const params = { limit: itemsLimit };

        if (lastCreated) {
            params.lastCreated = lastCreated;
        }

        try {
            const response = await axios.get('https://us-central1-bycbase-c9b76.cloudfunctions.net/fetchProjects', { params });

            const fetchedItems = response.data.items;
            const lastCreatedTimestamp = response.data.lastCreated;

            this.setState(prevState => ({
                items: [...prevState.items, ...fetchedItems],
                loading: false,
                lastCreated: lastCreatedTimestamp,
                hasMore: fetchedItems.length % itemsLimit === 0,
            }));

        } catch (error) {
            console.error('Error fetching projects:', error);
            this.setState({ error: error.message, loading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchItemsData ();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLoadMore = () => {
        this.fetchItemsData();
    };

    render() {
        const currentUrl = window.location.href;
        const { hasMore, loading, error, items } = this.state;

        if (loading && items.length === 0) {
            return <CenteredCircularProgress />;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>
                <Helmet>
                    <title>Projects - Bright Youth Community</title>
                    <meta name="description" content="Welcome to our page featuring the projects our association is involved in and the developments related to these projects. This page is like an exhibition showcasing our areas of work." />
                    <link rel="canonical" href={currentUrl} />

                    <meta property="og:title" content="Projects - Bright Youth Community" />
                    <meta property="og:description" content="Welcome to our page featuring the projects our association is involved in and the developments related to these projects. This page is like an exhibition showcasing our areas of work." />
                    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Projects - Bright Youth Community" />
                    <meta name="twitter:description" content="Welcome to our page featuring the projects our association is involved in and the developments related to these projects. This page is like an exhibition showcasing our areas of work." />
                    <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/bycbase-c9b76.appspot.com/o/byc%2Fbyc.jpg?alt=media&token=62f03ab7-9dbe-4f98-9481-cd2d4e270b83" />
                </Helmet>

                <Container className="mt-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Projects</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <Container className="mt-4 mb-5">
                    <Row>
                        <Col xs={12}>
                            <h2>Projects</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ProjectList projects={items} />
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-4 mb-4">
                    <Row>
                        <Col xs={12} className="text-center">
                            {loading && items.length > 0 && (
                                <CenteredCircularProgress />
                            )}
                            {!loading && hasMore && (
                                <Button onClick={this.handleLoadMore} variant="primary">Load More</Button>
                            )}
                            {/* 
                            {!hasMore && !loading && (
                                <p>No more news available.</p>
                            )} */}
                        </Col>
                    </Row>
                </Container>

                <div>

                    <BottomSide />
                </div>
            </div>
        )
    }
}

export default ProjectsPage;