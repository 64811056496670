import React from 'react'
import { Nav } from 'react-bootstrap'

const SignedOutLinks = () => {
    return (
        <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/news">News</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>

            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
    )
}

export default SignedOutLinks